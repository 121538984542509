import React from 'react'
import SliderSuccess from '../../../Slider/SliderSuccess'

export default function Section10() {
  return (
    <div className='py-10'>
      <div className='relative'>
        <div className='lg:px-20'>
          <img className='w-[15%] lg:w-auto absolute -top-10 lg:-top-24 left-7 lg:left-20 z-10' src='/assets/Group 1597883322.png'></img>
        </div>
        <div className=' mt-20'>
          <SliderSuccess />
        </div>
      </div>
    </div>
  )
}
