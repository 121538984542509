import React from 'react'
import SliderTailor from '../../../Slider/SliderTailor'

export default function Section3() {
    return (
        <div className='min-h-screen py-10 bg-sec1BG bg-cover flex items-center'>
            <div className='w-[100%]'>
                <h1 className='text-3xl lg:text-4xl text-center'>Tailored Products </h1>
                <p className='mt-7 text-center text-black/75 w-[90%] md:w-[60%] mx-auto tracking-widest'>Our specialized products are designed to solve key challenges and meet diverse market needs</p>
                <div className='w-[90%] mx-auto mt-10'>
                    <SliderTailor />
                </div>
            </div>
        </div>
    )
}
