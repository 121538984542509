import React from "react";
import Header from "../Layout/Header";
import Footer2 from "../Layout/Footer2";



export default function Service (){
    return (
        <>
        <Header/>
            <div className="font-inter w-full bg-cover bg-center min-h-screen bg-no-repeat text-white relative " style={{backgroundImage:"url('/assets/service-bg.png')"}}>
                <div className="container mx-auto w-full py-20">
                    <h2 className="font-bold text-center block w-full mx-auto uppercase lg:text-2xl text-xl">our services</h2>
                    <div className='flex justify-center'>
                    <div className='w-[30%]'>
                    <img className='animate-moveSmallp lg:animate-moveLargep' src='/assets/Subtract (1).png'></img>
                    </div>
                  </div>
                  <div className='flex justify-center'>
                  <p className='border border-b w-[30%]'></p>
                  </div>
                    <div className="lg:w-[78%] sm:w-[90%] w-full mx-auto my-10">
                        <p className="font-inter md:text-base text-center text-sm uppercase font-light tracking-widest">EMPOWERING YOUR BUSINESS WITH INNOVATIVE AND ENTREPRENEURIAL SOLUTIONS</p>
                        <h5 className="lg:text-2xl text-lg tracking-wider pt-10 pb-20 uppercase font-light text-center">HIGHLIGHT EACH SERVICE WITH A FOCUS ON HOW THEY ADDRESS SPECIFIC CHALLENGES AND PROVIDE IMPACTFUL SOLUTIONS</h5>
                        <p className="md:text-base tracking-wider text-sm text-center w-full mx-auto text-[#E8BA3C] my-6 font-light uppercase">Ready to Enhance Your Digital Presence?</p>
                        <div className="flex items-center justify-center w-full lg:my-8 my-6">
                        <a href='/Contactus'><button className=" bg-gradient-to-r from-[#4F93FF] via-[#27576500] to-[#E8BA3CA6] border border-[#4CC5E2] text-white font-bold text-lg py-4 px-12 border-b-[#E8BA3C] border-b-4 uppercase">CONTACT US</button></a>
                        </div>
                    </div>
                </div>
                <div className="2xl:mt-40 lg:mt-20nvm su">
                <Footer2/>
                </div>
            </div>
        </>
    )
}