import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

import Landing from './Components/Pages/V2/Landing/Landing';
import Products from './Components/Pages/Products';
import Service from './Components/Pages/Service';
import ContactUs from './Components/Pages/ContactUs';
import Landing2 from './Components/Pages/Landing';


function Routers() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/landing" element={<Landing2/>} />

      <Route path="/Products" element={<Products />} />
      <Route path="/Service" element={<Service />} />
      <Route path="/Contactus" element={<ContactUs />} />
    </Routes>
  );
}

export default Routers;