import React from 'react'
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

export default function Section6() {
    return (
        <div className='bg-[#0D327E] text-white'>
            <div className='min-h-screen bg-sec6BG bg-cover py-10'>
                <h1 className='text-3xl lg:text-4xl text-center'>Road Map</h1>
                <div className='relative mt-10 pb-28'>
                    <div className='flex flex-col lg:flex-row gap-10 lg:gap-0 justify-between w-[90%] mx-auto'>
                        <div className='w-[100%] lg:w-[45%]' data-aos='fade-up' data-aos-duration='1000'>
                            <h1 className='text-xl'>Q1 2025: Research & Optimization </h1>
                            <div className='p-4 rounded-md bg-[#071C50] mt-3 mx-3'>
                                <ul className='list-disc pl-5 text-sm 2xl:text-base space-y-3'>
                                    <li>DAB: Focus on optimizing AI algorithms and enhancing bot performance based on user feedback. </li>
                                    <li>RWT: Expand partnerships for tokenizing new asset classes. </li>
                                    <li>SMP: Research entry into new industries for skill trading expansion </li>
                                    <li>ORD: Continue exploring AI-driven healthcare analytics powered by blockchain for personalized health solutions. </li>
                                </ul>
                            </div>
                        </div>
                        <div className='w-[100%] lg:w-[45%]' data-aos='fade-up' data-aos-duration='1250'>
                            <div className='lg:translate-y-28' >
                                <h1 className='text-xl'>Q2 2025: Launch & Expansion </h1>
                                <div className='p-4 rounded-md bg-[#071C50] mt-3 mx-3'>
                                    <ul className='list-disc pl-5 text-sm 2xl:text-base space-y-3'>
                                        <li>TTC: Complete beta testing with early adopters and address final improvements before launch.</li>
                                        <li> DAB: Introduce advanced automation features for decentralized workflows </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row gap-10 lg:gap-0 justify-between w-[90%] mx-auto mt-10'>
                        <div className='w-[100%] lg:w-[45%]' data-aos='fade-up' data-aos-duration='1500'>
                            <h1 className='text-xl'>Q3 2025: Scaling & Partnerships  </h1>
                            <div className='p-4 rounded-md bg-[#071C50] mt-3 mx-3'>
                                <ul className='list-disc pl-5 text-sm 2xl:text-base space-y-3'>
                                    <li>TTC: Launch industry-specific offerings in healthcare , supply chain & legal rights </li>
                                    <li>SMP: Strengthen partnerships with educational institutions and corporates. </li>
                                    <li>ORD: Explore new opportunities in quantum , metaverse and decentralized cloud services for future tech advancements. </li>
                                </ul>
                            </div>
                        </div>
                        <div className='w-[100%] lg:w-[45%]' data-aos='fade-up' data-aos-duration='1750'>
                            <div className='lg:translate-y-28'>
                                <h1 className='text-xl'>Q4 2025: Future-Ready Innovations </h1>
                                <div className='p-4 rounded-md bg-[#071C50] mt-3 mx-3'>
                                    <ul className='list-disc pl-5 text-sm 2xl:text-base space-y-3'>
                                        <li>RWT: Introduce advanced asset management features for token holders. </li>
                                        <li>SMP: Expand to international markets and introduce new use cases. </li>
                                        <li>TTC: Explore IoT and AI integrations for predictive supply chain solutions </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bg-gradient-to-b from-[#99999980] via-white to-[#99999980] absolute h-[80vh] 2xl:h-[70vh] w-1 top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] hidden lg:block rounded-md'>
                    </div>
                </div>
            </div>
        </div>
    )
}
