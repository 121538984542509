import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faChevronLeft, faChevronRight, faPlay } from "@fortawesome/free-solid-svg-icons";
import '../Styles/Tailor.css';

export default function SliderTailor() {

    const Tailor = [
        {
            product_name: 'DAB - Decentralized AI Arbitrage BOT ',
            product_content: 'An AI-powered trading platform offering multiple models with no collateral or credit score required. It includes flash loans, sniper bots for market opportunities, and matrix bots for execution. Users can choose low-risk automation or high-risk capital-invested strategies, including copy trading or a hybrid of decentralized and centralized systems. ',
        },
        {
            product_name: 'RWT - Real World Asset Tokenization  ',
            product_content: 'A blockchain-based platform that tokenizes real estate and bonds, enabling fractional ownership through digital tokens. This makes traditionally high-entry markets accessible to a broader range of investors by dividing assets into tradeable shares.  ',
        },
    ]

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className="absolute transform  md:right-[2%] lg:right-[4%] -bottom-[17%] lg:-bottom-[20%] -translate-y-[50%] w-10 h-10" style={{ zIndex: 1 }}
        >
            <div className="cursor-pointer bg-gradient-to-r from-[#FFBB00] to-[#3C79D4] rounded-md p-[2px] h-8 w-8">
                <div className='flex justify-center items-center w-full h-full bg-[#0C0263] rounded-md'>
                    <FontAwesomeIcon icon={faChevronLeft} className="text-white font-bold cursor-pointer" />
                </div>
            </div>
        </div>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className="absolute transform -right-[5%] lg:right-0 -bottom-[17%] lg:-bottom-[20%]  -translate-y-[50%] w-10 h-10" style={{ zIndex: 1 }}
        >
            <div className="cursor-pointer bg-gradient-to-r from-[#FFBB00] to-[#3C79D4] rounded-md p-[2px] h-8 w-8">
                <div className='flex justify-center items-center w-full h-full bg-[#0C0263] rounded-md'>
                    <FontAwesomeIcon icon={faChevronRight} className="text-white font-bold cursor-pointer" />
                </div>
            </div>
        </div>
    );

    const settings = {
        infinite: true,
        speed: 500,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div className='pb-7'>
            <Slider className='w-[90%] 2xl:w-[80%] mx-auto' {...settings}>
                {Tailor.map((item, index) => (
                    <div key={index} className='bg-[#133D8E] text-white rounded-lg px-5 pt-5 pb-2 md:pb-0 md:px-10 md:pt-7' >
                        <h1 className='font-semibold text-xl'>{item.product_name}</h1>
                        <p className='mt-2 text-white/75'>{item.product_content}</p>
                        <div className='hidden lg:block'>
                            <h2 className='mt-5'>PRODUCT UPDATE</h2>
                            <div className='flex justify-around gap-3 mt-2 px-3'>
                                <div className='bg-[#062157] px-7 py-1 rounded-md'>IDEATION</div>
                                <div className='bg-[#062157] px-7 py-1 rounded-md'>VALIDATION</div>
                                <div className='bg-[#062157] px-7 py-1 rounded-md'>DOCUMENTATION</div>
                                <div className='bg-[#062157] px-7 py-1 rounded-md'>DEVELOP</div>
                                <div className='bg-[#062157] px-7 py-1 rounded-md'>TEST</div>
                                <div className='bg-[#062157] px-7 py-1 rounded-md'>LAUNCH</div>
                            </div>
                            <input type='range' className='mt-4 w-[100%] tailor-range'></input>
                        </div>
                        <img src='/assets/Others/Line.svg' className='mx-auto'></img>
                    </div>
                ))}
            </Slider>
        </div>
    )
}
