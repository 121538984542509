import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import SliderTestimonial from './SliderTestimonial';

export default function SliderSuccess() {
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className="absolute transform -bottom-12 md:bottom-8 left-8 md:left-auto md:right-[10%] z-10 w-10 h-10"
        >
            <div className="cursor-pointer bg-gradient-to-r from-[#FFBB00] to-[#3C79D4] rounded-md p-[2px] h-8 w-8">
                <div className='flex justify-center items-center w-full h-full bg-[#0C0263] rounded-md'>
                    <FontAwesomeIcon icon={faChevronLeft} className="text-white font-bold cursor-pointer" />
                </div>
            </div>
        </div>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className="absolute transform -bottom-12 md:bottom-8 right-7 md:right-[7%] w-10 h-10"
        >
            <div className="cursor-pointer bg-gradient-to-r from-[#FFBB00] to-[#3C79D4] rounded-md p-[2px] h-8 w-8">
                <div className='flex justify-center items-center w-full h-full bg-[#0C0263] rounded-md'>
                    <FontAwesomeIcon icon={faChevronRight} className="text-white font-bold cursor-pointer" />
                </div>
            </div>
        </div>
    );

    const settingsSuccess = {
        infinite: true,
        speed: 500,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
    };

    const settings = {
        infinite: true,
        speed: 500,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

    return (
        <div>
            <Slider {...settingsSuccess}>
                <div>
                    <div className='w-[90%] bg-[#0D327E] mx-auto flex flex-col lg:flex-row gap-10 justify-center items-center px-7 pt-7 md:px-10 md:pt-10 pb-20'>
                        <div className='w-[100%] lg:w-[30%] flex items-center justify-center'>
                            <div>
                                <p className='text-center lg:text-2xl font-sego  uppercase text-white'><span>Decentralised <br /> AI Bot </span></p>
                                <p className='text-white font-sego text-center font-medium mt-5'>DAB is an AI-integrated, automation-driven trading platform that empowers users to trade profitably in DeFi without collateral or credit scores. Using real-time market analysis, DAB identifies patterns and trends, enabling users to execute profitable trades effortlessly.</p>
                            </div>

                        </div>
                        <div className='w-[100%] lg:w-[70%] flex flex-col gap-7'>
                            <p className='font-normal font-sego lg:text-2xl text-center text-white'>What our clients are saying </p>
                            <div className='hidden md:block'>
                                <div className='grid grid-cols-3 gap-5'>
                                    <div className=" bg-black/60 text-white shadow-lg overflow-hidden">
                                        <div className='bg-[#185cc2] '>
                                            <img className="w-full" src="/assets/Test1.png" alt="Slide 3" />
                                            <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                        </div>
                                        <div className="bg-[#fff] py-5 px-5 h-full">
                                            <div className="flex justify-end items-center">
                                                <img src='/assets/Stars.png' alt="icon" />
                                            </div>
                                            <div className="flex flex-col gap-5 text-sm mt-4 font-inter">
                                                <p className='text-left font-bold text-black'>Captain Ledger</p>
                                                <p className='text-left text-black'>
                                                Thrilled to Work with Such an Innovative Team!- We couldn't be happier with our choice to partner with this team. Their expertise in blockchain and secure data management has exceeded our expectations at every turn. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" bg-black/60 text-white shadow-lg overflow-hidden">
                                        <div className='bg-[#185cc2] '>
                                            <img className="w-full" src="/assets/Test2.png" alt="Slide 1" />
                                            <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                        </div>
                                        <div className="bg-[#fff] py-5 px-5 h-full">
                                            <div className="flex justify-end items-center">
                                                <img src='/assets/Stars.png' alt="icon" />
                                            </div>
                                            <div className="flex flex-col gap-5 text-sm mt-4">
                                                <p className='text-left font-bold text-black'>Mr. Tokenator </p>
                                                <p className='text-left  text-black'>
 Incredible Guidance and Product Vision!- We are beyond excited to be collaborating with this team on our Track & Trace platform! Their consultative approach has been invaluable, guiding us every step of the way to ensure we’re building a truly impactful product.                                                  </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" bg-black/60 text-white shadow-lg overflow-hidden">
                                        <div className='bg-[#185cc2] '>
                                            <img className="w-full" src="/assets/Test3.png" alt="Slide 2" />
                                            <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                        </div>
                                        <div className="bg-[#fff] py-5 px-5 h-full">
                                            <div className="flex justify-end items-center">
                                                <img src='/assets/Stars (1).png' alt="icon" />
                                            </div>
                                            <div className="flex flex-col gap-5 text-sm mt-4">
                                                <p className='text-left text-black font-bold'>Miss Satoshi </p>
                                                <p className='text-left text-black'>
                                                Their unwavering support and expertise have made a significant impact on our journey. Their commitment to understanding our vision and offering innovative solutions has been impressive. We could not have asked for a better partner. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='block md:hidden'>
                                <SliderTestimonial />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='w-[90%] bg-[#0D327E] mx-auto flex flex-col lg:flex-row gap-10 justify-center items-center px-7 pt-7 md:px-10 md:pt-10 pb-20'>
                        <div className='w-[100%] lg:w-[30%] flex items-center justify-center'>
                            <div>
                                <p className='text-center lg:text-2xl font-sego font-bold uppercase text-white'><span>Real World Asset <br /> Tokenization </span></p>
                                <p className='text-white font-inter text-center font-medium mt-5'>Our RWT platform tokenizes physical assets like real estate, dividing them into digital tokens to make property ownership accessible to a wider range of investors.
                                </p>
                            </div>
                        </div>
                        <div className='w-[100%] lg:w-[70%] flex flex-col gap-7'>
                            <p className='font-normal font-sego lg:text-2xl text-center text-white'>What our clients are saying </p>
                            <div className='flex'>
                                <div className="w-[100%] lg:w-[30%] mx-auto bg-black/60 text-white shadow-lg overflow-hidden">
                                    <div className='bg-[#185cc2] '>
                                        <img className="w-full" src="/assets/Others/Test5.png" alt="Slide 2" />
                                        <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                    </div>
                                    <div className="bg-[#fff] py-5 px-5 h-full">
                                        <div className="flex justify-end items-center">
                                            <img src='/assets/Stars.png' alt="icon" />
                                        </div>
                                        <div className="flex flex-col gap-5 text-sm mt-4">
                                            <p className='text-left text-black font-bold'>"Thrilled to Work with Such an Innovative Team!"</p>
                                            <p className='text-left text-black'>
                                                Their expertise in blockchain and secure data management has transformed how we handle asset tokenization. The process is seamless and secure.                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[100%] hidden lg:block  lg:w-[30%] mx-auto bg-black/60 text-white shadow-lg overflow-hidden">
                                <div className='bg-[#185cc2] '>
                                    <img className="w-full" src="/assets/Others/Test5.png" alt="Slide 2" />
                                    <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                </div>
                                <div className="bg-[#fff] py-5 px-5 h-full">
                                    <div className="flex justify-end items-center">
                                        <img src='/assets/Stars.png' alt="icon" />
                                    </div>
                                    <div className="flex flex-col gap-5 text-sm mt-4">
                                        <p className='text-left text-black font-bold'>"Thrilled to Work with Such an Innovative Team!"</p>
                                        <p className='text-left text-black'>
                                            Their expertise in blockchain and secure data management has transformed how we handle asset tokenization. The process is seamless and secure.                                            </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-[100%] hidden lg:block  lg:w-[30%] mx-auto bg-black/60 text-white shadow-lg overflow-hidden">
                            <div className='bg-[#185cc2] '>
                                <img className="w-full" src="/assets/Others/Test5.png" alt="Slide 2" />
                                <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                            </div>
                            <div className="bg-[#fff] py-5 px-5 h-full">
                                <div className="flex justify-end items-center">
                                    <img src='/assets/Stars.png' alt="icon" />
                                </div>
                                <div className="flex flex-col gap-5 text-sm mt-4">
                                    <p className='text-left text-black font-bold'>"Thrilled to Work with Such an Innovative Team!"</p>
                                    <p className='text-left text-black'>
                                        Their expertise in blockchain and secure data management has transformed how we handle asset tokenization. The process is seamless and secure.                                            </p>
                                </div>
                            </div>
                        </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='w-[90%] bg-[#0D327E] mx-auto flex flex-col lg:flex-row gap-10 justify-center items-center px-7 pt-7 md:px-10 md:pt-10 pb-20'>
                        <div className='w-[100%] lg:w-[30%] flex items-center justify-center'>
                            <div>
                                <p className='text-center lg:text-2xl font-sego font-bold uppercase text-white'><span>Tracking & Tracing Chain (TTC): Supply Chain Transparency </span></p>
                                <p className='text-white font-inter text-center font-medium mt-5'>TTC leverages blockchain to provide full transparency across supply chains, whether it's tracing coffee beans or monitoring livestock. This platform builds trust and optimizes operations through immutable, traceable records.
                                </p>
                            </div>
                        </div>
                        <div className='w-[100%] lg:w-[70%] flex flex-col gap-7'>
                            <p className='font-normal font-sego lg:text-2xl text-center text-white'>What our clients are saying </p>
                            <div className='hidden lg:block'>
                                <div className='flex gap-5 justify-center'>
                                    <div className="w-[100%] lg:w-[32%] bg-black/60 text-white shadow-lg overflow-hidden">
                                        <div className='bg-[#185cc2] '>
                                            <img className="w-full h-[163px]" src="/assets/Test4.png" alt="Slide 2" />
                                            <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                        </div>
                                        <div className="bg-[#fff] py-5 px-5 h-full">
                                            <div className="flex justify-end items-center">
                                                <img src='/assets/Stars (1).png' alt="icon" />
                                            </div>
                                            <div className="flex flex-col gap-3 text-sm mt-4">
                                                <p className='text-left text-black font-semibold'>Coffee Track & Trace</p>
                                                <p className='text-left text-black'>"Incredible Guidance and Product Vision"</p>
                                                <p className='text-left text-black'>
                                                    The team’s insights helped us create a platform that provides full transparency in our coffee supply chain, setting us apart in the market.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-[100%] lg:w-[32%] bg-black/60 text-white shadow-lg overflow-hidden">
                                        <div className='bg-[#185cc2] '>
                                            <img className="w-full" src="/assets/Test3.png" alt="Slide 2" />
                                            <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                        </div>
                                        <div className="bg-[#fff] py-5 px-5 h-full">
                                            <div className="flex justify-end items-center">
                                                <img src='/assets/Stars.png' alt="icon" />
                                            </div>
                                            <div className="flex flex-col gap-3 text-sm mt-4">
                                                <p className='text-left text-black font-semibold'>Livestock Passport</p>
                                                <p className='text-left text-black'>"Visionary Collaboration in Livestock Management"</p>
                                                <p className='text-left text-black'>
                                                    Their guidance shaped a platform that tracks every aspect of livestock management. AI-driven insights have greatly improved our efficiency and product quality.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-[100%] lg:w-[32%]  bg-black/60 text-white shadow-lg overflow-hidden">
                                    <div className='bg-[#185cc2] '>
                                        <img className="w-full" src="/assets/Test3.png" alt="Slide 2" />
                                        <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                    </div>
                                    <div className="bg-[#fff] py-5 px-5 h-full">
                                        <div className="flex justify-end items-center">
                                            <img src='/assets/Stars.png' alt="icon" />
                                        </div>
                                        <div className="flex flex-col gap-3 text-sm mt-4">
                                            <p className='text-left text-black font-semibold'>Livestock Passport</p>
                                            <p className='text-left text-black'>"Visionary Collaboration in Livestock Management"</p>
                                            <p className='text-left text-black'>
                                                Their guidance shaped a platform that tracks every aspect of livestock management. AI-driven insights have greatly improved our efficiency and product quality.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className='block lg:hidden'>
                                <Slider {...settings}>
                                    <div className='px-1'>
                                    <div className="w-[100%] bg-black/60 text-white shadow-lg overflow-hidden">
                                        <div className='bg-[#185cc2] '>
                                            <img className="w-full" src="/assets/Test4.png" alt="Slide 2" />
                                            <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                        </div>
                                        <div className="bg-[#fff] py-5 px-5 h-full">
                                            <div className="flex justify-end items-center">
                                                <img src='/assets/Stars (1).png' alt="icon" />
                                            </div>
                                            <div className="flex flex-col gap-3 text-sm mt-4">
                                                <p className='text-black text-left font-semibold'>Coffee Track & Trace</p>
                                                <p className='text-black text-left'>"Incredible Guidance and Product Vision"</p>
                                                <p className='text-black text-left'>
                                                    The team’s insights helped us create a platform that provides full transparency in our coffee supply chain, setting us apart in the market.</p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className='px-1'>
                                    <div className="w-[100%]  bg-black/60 text-white shadow-lg overflow-hidden">
                                        <div className='bg-[#185cc2] '>
                                            <img className="w-full" src="/assets/Test3.png" alt="Slide 2" />
                                            <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                        </div>
                                        <div className="bg-[#fff] py-5 px-5 h-full">
                                            <div className="flex justify-end items-center">
                                                <img src='/assets/Stars.png' alt="icon" />
                                            </div>
                                            <div className="flex flex-col gap-3 text-sm mt-4">
                                                <p className='text-left text-black font-semibold'>Livestock Passport</p>
                                                <p className='text-left text-black'>"Visionary Collaboration in Livestock Management"</p>
                                                <p className='text-left text-black'>
                                                    Their guidance shaped a platform that tracks every aspect of livestock management. AI-driven insights have greatly improved our efficiency and product quality.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='w-[90%] bg-[#0D327E] mx-auto flex flex-col lg:flex-row gap-10 justify-center items-center px-7 pt-7 md:px-10 md:pt-10 pb-20'>
                        <div className='w-[100%] lg:w-[30%] flex items-center justify-center'>
                            <div>
                                <p className='text-center lg:text-2xl font-sego font-bold uppercase text-white'><span>Skill Marketplace
                                    Skilleareum: Web 3.0 Learning & Earning Platform</span></p>
                                <p className='text-white font-inter text-center font-medium mt-5'>Skilleareum revolutionizes online education with its decentralized Zero Knowledge Proof of Learning (zKPoL) consensus mechanism. It incentivizes learners through a Smart Minting System, rewarding engagement and achievements.
                                </p>
                            </div>
                        </div>
                        <div className='w-[100%] lg:w-[70%] flex flex-col gap-7'>
                            <p className='font-normal font-sego lg:text-2xl text-center text-white'>What our clients are saying </p>
                            <div className='flex gap-5 justify-center overflow-x-auto'>
                                <div className=" w-[100%] lg:w-[30%] bg-black/60 text-white shadow-lg overflow-hidden">
                                    <div className='bg-[#185cc2] '>
                                        <img className="w-full" src="/assets/Test7.png" alt="Slide 2" />
                                        <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                    </div>
                                    <div className="bg-[#fff] py-5 px-5 h-full">
                                        <div className="flex justify-end items-center">
                                            <img src='/assets/Stars.png' alt="icon" />
                                        </div>
                                        <div className="flex flex-col gap-5 text-sm mt-4">
                                            <p className='text-left text-black font-bold'>"A Game-Changer for Online Education"</p>
                                            <p className='text-left text-black'>
                                                Skilleareum’s decentralized, reward-driven model has transformed how we approach education, driving engagement and measurable outcomes..
                                            </p>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className=" w-[100%]  hidden lg:block lg:w-[30%] bg-black/60 text-white shadow-lg overflow-hidden">
                                <div className='bg-[#185cc2] '>
                                    <img className="w-full" src="/assets/Test7.png" alt="Slide 2" />
                                    <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                </div>
                                <div className="bg-[#fff] py-5 px-5 h-full">
                                    <div className="flex justify-end items-center">
                                        <img src='/assets/Stars.png' alt="icon" />
                                    </div>
                                    <div className="flex flex-col gap-5 text-sm mt-4">
                                        <p className='text-left text-black font-bold'>"A Game-Changer for Online Education"</p>
                                        <p className='text-left text-black'>
                                            Skilleareum’s decentralized, reward-driven model has transformed how we approach education, driving engagement and measurable outcomes..
                                        </p>
                                    </div>
                                </div>
                                
                            </div>
                            <div className=" w-[100%]  hidden lg:block lg:w-[30%] bg-black/60 text-white shadow-lg overflow-hidden">
                            <div className='bg-[#185cc2] '>
                                <img className="w-full" src="/assets/Test7.png" alt="Slide 2" />
                                <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                            </div>
                            <div className="bg-[#fff] py-5 px-5 h-full">
                                <div className="flex justify-end items-center">
                                    <img src='/assets/Stars.png' alt="icon" />
                                </div>
                                <div className="flex flex-col gap-5 text-sm mt-4">
                                    <p className='text-left text-black font-bold'>"A Game-Changer for Online Education"</p>
                                    <p className='text-left text-black'>
                                        Skilleareum’s decentralized, reward-driven model has transformed how we approach education, driving engagement and measurable outcomes..
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='w-[90%] bg-[#0D327E] lg:h-[615px] mx-auto flex flex-col lg:flex-row gap-10 justify-center items-center px-7 pt-7 md:px-10 md:pt-10 pb-20'>
                        <div className='w-[100%] lg:w-[32%] flex items-center justify-center'>
                            <div>
                                <p className='text-center lg:text-2xl font-sego font-bold uppercase text-white'><span>MLM Platform with Smart Contract Payout Development</span></p>
                                <p className='text-white font-inter text-center font-medium mt-5'>We provide strategic insights and unmatched support in developing multi-level marketing platforms that offer innovative features and exceptional user experiences.
                                </p>
                            </div>
                        </div>
                        <div className='w-[100%] lg:w-[70%] flex flex-col gap-7'>
                            <p className='font-normal font-sego lg:text-2xl text-center text-white'>What our clients are saying </p>
                            <div className='flex gap-5 justify-center overflow-x-auto'>
                                <div className=" w-[100%] lg:w-[30%] bg-black/60 text-white shadow-lg overflow-hidden">
                                    <div className='bg-[#185cc2] '>
                                        <img className="w-full" src="/assets/Test6.png" alt="Slide 2" />
                                        <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                    </div>
                                    <div className="bg-[#fff] py-5 px-5 h-full">
                                        <div className="flex justify-end items-center">
                                            <img src='/assets/Stars.png' alt="icon" />
                                        </div>
                                        <div className="flex flex-col gap-5 text-sm mt-4">
                                            <p className='text-left text-black font-bold'>"Unmatched Support and Strategic Insight"</p>
                                            <p className='text-left text-black'>
                                                Their expertise in MLM systems was critical to our platform’s success. The strategic insights they provided enhanced the platform’s functionality.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className=" w-[100%] lg:w-[30%]  hidden lg:block bg-black/60 text-white shadow-lg overflow-hidden">
                                    <div className='bg-[#185cc2] '>
                                        <img className="w-full" src="/assets/Test6.png" alt="Slide 2" />
                                        <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                    </div>
                                    <div className="bg-[#fff] py-5 px-5 h-full">
                                        <div className="flex justify-end items-center">
                                            <img src='/assets/Stars.png' alt="icon" />
                                        </div>
                                        <div className="flex flex-col gap-5 text-sm mt-4">
                                            <p className='text-left text-black font-bold'>"Unmatched Support and Strategic Insight"</p>
                                            <p className='text-left text-black'>
                                                Their expertise in MLM systems was critical to our platform’s success. The strategic insights they provided enhanced the platform’s functionality.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className=" w-[100%] lg:w-[30%]  hidden lg:block bg-black/60 text-white shadow-lg overflow-hidden">
                                    <div className='bg-[#185cc2] '>
                                        <img className="w-full" src="/assets/Test6.png" alt="Slide 2" />
                                        <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                    </div>
                                    <div className="bg-[#fff] py-5 px-5 h-full">
                                        <div className="flex justify-end items-center">
                                            <img src='/assets/Stars.png' alt="icon" />
                                        </div>
                                        <div className="flex flex-col gap-5 text-sm mt-4">
                                            <p className='text-left text-black font-bold'>"Unmatched Support and Strategic Insight"</p>
                                            <p className='text-left text-black'>
                                                Their expertise in MLM systems was critical to our platform’s success. The strategic insights they provided enhanced the platform’s functionality.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='w-[90%] bg-[#0D327E] mx-auto flex flex-col lg:flex-row gap-10 justify-center items-center px-7 pt-7 md:px-10 md:pt-10 pb-20'>
                        <div className='w-[100%] lg:w-[30%] flex items-center justify-center'>
                            <div>
                                <p className='text-center lg:text-2xl font-sego font-bold uppercase text-white'><span>Wallet & Token Development</span></p>
                                <p className='text-white font-inter text-center font-medium mt-5'>Our wallet and token development services focus on security, scalability, and user-friendly design, ensuring a seamless experience for your digital asset management.
                                </p>
                            </div>
                        </div>
                        <div className='w-[100%] lg:w-[70%] flex flex-col gap-7'>
                            <p className='font-normal font-sego lg:text-2xl text-center text-white'>What our clients are saying </p>
                            <div className='flex gap-5 justify-center overflow-x-auto'>
                                <div className=" w-[100%] lg:w-[30%] bg-black/60 text-white shadow-lg overflow-hidden">
                                    <div className='bg-[#185cc2] '>
                                        <img className="w-full" src="/assets/Test8.png" alt="Slide 2" />
                                        <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                    </div>
                                    <div className="bg-[#fff] py-5 px-5 h-full">
                                        <div className="flex justify-end items-center">
                                            <img src='/assets/Stars.png' alt="icon" />
                                        </div>
                                        <div className="flex flex-col gap-5 text-sm mt-4">
                                            <p className='text-left text-black font-bold'>"Outstanding Technical Proficiency and Collaboration"</p>
                                            <p className='text-left text-black'>
                                                Their technical expertise in blockchain development exceeded our expectations. Security and innovation were key, and they delivered on both fronts.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className=" w-[100%]  hidden lg:block lg:w-[30%] bg-black/60 text-white shadow-lg overflow-hidden">
                                <div className='bg-[#185cc2] '>
                                    <img className="w-full" src="/assets/Test8.png" alt="Slide 2" />
                                    <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                                </div>
                                <div className="bg-[#fff] py-5 px-5 h-full">
                                    <div className="flex justify-end items-center">
                                        <img src='/assets/Stars.png' alt="icon" />
                                    </div>
                                    <div className="flex flex-col gap-5 text-sm mt-4">
                                        <p className='text-left text-black font-bold'>"Outstanding Technical Proficiency and Collaboration"</p>
                                        <p className='text-left text-black'>
                                            Their technical expertise in blockchain development exceeded our expectations. Security and innovation were key, and they delivered on both fronts.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className=" w-[100%]  hidden lg:block lg:w-[30%] bg-black/60 text-white shadow-lg overflow-hidden">
                            <div className='bg-[#185cc2] '>
                                <img className="w-full" src="/assets/Test8.png" alt="Slide 2" />
                                <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
                            </div>
                            <div className="bg-[#fff] py-5 px-5 h-full">
                                <div className="flex justify-end items-center">
                                    <img src='/assets/Stars.png' alt="icon" />
                                </div>
                                <div className="flex flex-col gap-5 text-sm mt-4">
                                    <p className='text-left text-black font-bold'>"Outstanding Technical Proficiency and Collaboration"</p>
                                    <p className='          text-left text-black'>
                                        Their technical expertise in blockchain development exceeded our expectations. Security and innovation were key, and they delivered on both fronts.
                                    </p>
                                </div>
                            </div>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}
