import React from 'react'
import "../Style/Landing.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowRightLong, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import CountUp from 'react-countup';


export default function Section2() {
    return (
        <div className='bg-[#0D327E] pt-10 lg:pt-20'>
            <div className='w-[90%] mx-auto text-white'>
                <p className='text-center leading-10 text-lg'>At FABC LABS, our vision is to empower entrepreneurs through innovative products and go-to-market strategies crafted by subject matter experts. We’re committed to driving the next generation of deep-tech solutions that transform industries and shape the future. </p>
                <div className='mt-10 grid grid-cols-2 md:flex md:flex-wrap gap-3 md:gap-10 justify-center text-center'>
                    <div className='white-shadow-box  bg-gradient-to-r from-[#062157] to-[#0D327E] px-6 py-4 rounded-md grid content-center'>
                        <h1 className='font-bold text-2xl md:text-4xl'># <CountUp start={0} end={5} duration={1} decimal=","></CountUp></h1>
                        <p className='mt-2 text-sm text-white/75'>Product IPs</p>
                    </div>
                    <div className='white-shadow-box  bg-gradient-to-r from-[#062157] to-[#0D327E] px-6 py-4 rounded-md grid content-center'>
                        <h1 className='font-bold text-2xl md:text-4xl'><CountUp start={0} end={20000} duration={3} decimal=","></CountUp>+</h1>
                        <p className='mt-2 text-sm text-white/75'>Skilled Professionals</p>
                    </div>
                    <div className='white-shadow-box  bg-gradient-to-r from-[#062157] to-[#0D327E] px-6 py-4 rounded-md grid content-center'>
                        <h1 className='font-bold text-2xl md:text-4xl'><CountUp start={0} end={15} duration={3} decimal=","></CountUp> +</h1>
                        <p className='mt-2 text-sm text-white/75'>Clients served</p>
                    </div>
                    <div className='white-shadow-box  bg-gradient-to-r from-[#062157] to-[#0D327E] px-6 py-4 rounded-md grid content-center'>
                        <h1 className='font-bold text-2xl md:text-4xl'><CountUp start={0} end={7} duration={3} decimal=","></CountUp>+</h1>
                        <p className='mt-2 text-sm text-white/75'>International Markets <br className='hidden md:block'/> Covered</p>
                    </div>
                </div>
            </div>
            <div className='mt-10 py-10 min-h-screen flex items-center relative overflow-hidden'>
                <div className='relative w-[90%] mx-auto text-white' style={{ zIndex: 1 }}>
                    <h1 className='text-3xl lg:text-4xl text-center'>Tools & Tech Stack  </h1>
                    <div data-aos='fade-up' data-aos-duration='1500' className='mt-10 max-w-screen-sm md:max-w-lg 2xl:max-w-xl text-black bg-gradient-to-b from-white to-[#D9D9D9] rounded-lg shadow-md p-3 md:p-10 mx-auto tools-shadow grid grid-cols-2'>
                        <div className='flex items-center gap-2  md:gap-5 py-5 pl-0 md:pl-7 '>
                            <img src='/assets/Icon/Tool_react.svg'></img>
                            <p className='text-base 2xl:text-xl'>React.js</p>
                        </div>
                        <div className='flex items-center gap-2  md:gap-5 py-5 pl-0 md:pl-7 '>
                            <img src='/assets/Icon/Tool_aws.svg'></img>
                            <p className='text-base 2xl:text-xl'>AWS</p>
                        </div>
                        <div className='flex items-center gap-2  md:gap-5 py-5 pl-0 md:pl-7 '>
                            <img src='/assets/Icon/Tool_tailwind.svg'></img>
                            <p className='text-base 2xl:text-xl'>Tailwind.css</p>
                        </div>
                        <div className='flex items-center gap-2  md:gap-5 py-5 pl-0 md:pl-7 '>
                            <img src='/assets/Icon/Tool_postman.svg'></img>
                            <p className='text-base 2xl:text-xl'>Postman</p>
                        </div>
                        <div className='flex items-center gap-2  md:gap-5 py-5 pl-0 md:pl-7 '>
                            <img src='/assets/Icon/Tool_node.svg'></img>
                            <p className='text-base 2xl:text-xl'>Node.js</p>
                        </div>
                        <div className='flex items-center gap-2  md:gap-5 py-5 pl-0 md:pl-7 '>
                            <img src='/assets/Icon/Tool_hardhat.svg'></img>
                            <p className='text-base 2xl:text-xl'>Hardhat</p>
                        </div>
                    </div>
                </div>
                <div className='z-0'>
                    <div className='small-circle'></div>
                    <div className='circle'></div>
                    <div className='small-square'></div>
                    <div className='medium-square'></div>
                    <div className='square'></div>
                    <div className='square-2'></div>
                </div>
            </div>
        </div>
    )
}
