import React from 'react'
import Header from '../Layout/Header'
import Footer2 from '../Layout/Footer2'

export default function Products() {
  return (
    <>
    <Header/>
    <div className="font-inter w-full bg-cover bg-center min-h-screen bg-no-repeat text-white relative " style={{backgroundImage:"url('/assets/animated_bg.gif')"}}>
        <div className="bg-gradient-to-r from-[#185CC2D9] via-[#0A2973D9] to-[#0E183700] min-h-screen">
            <div className="bg-gradient-to-t from-[#E8BA3C5C] to-[#E8BA3C00] min-h-screen">
                <div className="container mx-auto pt-20">
                    <div className="lg:w-[75%] sm:w-[90%] w-full mx-auto text-center">
                        <h2 className="font-bold text-center uppercase lg:text-2xl text-xl py-2">OUR products</h2>
                        <div className='flex justify-center'>
                        <div className='w-[30%]'>
                        <img className='animate-moveSmallp lg:animate-moveLargep' src='/assets/Subtract (1).png'></img>
                        </div>
                      </div>
                      <div className='flex justify-center'>
                      <p className='border border-b w-[30%]'></p>
                      </div>
                        <p className="text-center uppercase py-4 text-white tracking-wider sm:text-lg text-base">EXPLORE OUR CUTTING-EDGE INNOVATIONS TRANSFORMING INDUSTRIES</p>
                    </div>
                    <div className="lg:w-[90%] sm:w-[95%] w-full px-4">
                        <div className="my-4">
                            <h3 className="uppercase font-semibold tracking-wider text-center lg:text-start lg:text-2xl text-xl py-1">DETAILED DESCRIPTION</h3>
                            <div className='flex lg:justify-start justify-center'>
                        <div className='lg:w-[30%] w-[78%]'>
                        <img className='animate-moveSmall lg:animate-moveLargep' src='/assets/Subtract (1).png'></img>
                        </div>
                      </div>
                      <div className='flex lg:justify-start justify-center'>
                      <p className='border border-b lg:w-[30%] w-[78%]'></p>
                      </div>
                            <p className="uppercase tracking-wider lg:text-start text-center sm:text-lg text-base pt-1">Focus on the unique selling points of each products, such as security, transparency and efficiency.</p>
                        </div>
                        <div className="my-4">
                            <h3 className="uppercase font-semibold tracking-wider lg:text-2xl text-xl py-1">IP Benefits and Features</h3>
                            <div className='flex lg:justify-start justify-center'>
                        <div className='lg:w-[30%] w-[90%]'>
                        <img className='animate-moveSmall lg:animate-moveLargep' src='/assets/Subtract (1).png'></img>
                        </div>
                      </div>
                      <div className='flex lg:justify-start justify-center'>
                      <p className='border border-b lg:w-[30%] w-[90%]'></p>
                      </div>
                            <p className="uppercase tracking-wider lg:text-start text-center sm:text-lg text-base pt-1">Focus on the unique selling points of each products, such as security, transparency and efficiency.</p>
                        </div>
                        <div className="my-4 lg:w-[65%] sm:w-[85%] w-full text-center mx-auto">
                            <p className="text-[#E8BA3C] uppercase tracking-wider text-center md:text-base text-sm">Interested in Learning More?</p>
                            <div className="flex items-center justify-center w-full lg:my-8 my-6">
                            <a href='/Contactus'> <button className=" bg-gradient-to-r from-[#4F93FF] via-[#27576500] to-[#E8BA3CA6] border border-[#4CC5E2] text-white font-bold text-lg py-4 px-12 border-b-[#E8BA3C] border-b-4 uppercase">CONTACT US</button></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='lg:mt-40 mt-20'>
                <Footer2/>
                </div>
            </div>
        </div>
        
    </div>
</>
  )
}
