import React from 'react'

export default function Section11() {
  return (
    <div>
      <div className='Section-10 bg-[#FFF] text-center bg-  z-20  py-20 w-[100%] grid content-center'>
        <h1 className='font-sego tracking-widest text-4xl 2xl:text-5xl font-medium'>Say Hello to the Visionaries </h1>

        <p className='w-[90%] mx-auto mt-5 text-lg 2xl:text-2xl tracking-widest'>Our leadership team is a blend of bold thinkers and strategic doers, leading with innovation and expertise. With a passion for emerging tech like blockchain, AI, and decentralized systems, they drive us to break new ground every day. Focused on growth, impact, and excellence, they guide our mission to shape the future. </p>
        <div className='flex flex-col gap-10 lg:gap-0 md:flex-row items-start lg:items-center lg:justify-around w-[90%] mx-auto mt-20 lg:mt-16'>
          <div className='lg:w-[25%] ' data-aos='flip-right' data-aos-duration='1500'>
            <img src='/assets/Testimonials/Group 2.png'></img>
            <div className='flex flex-col gap-3 py-2'>
              <p className='font-bold uppercase text-lg lg:text-2xl'>Sakthi Visakan <br className='hidden md:block' />Rajaguru</p>
              <img src='/assets/Gradient-Line-768x1.png.png'></img>
              <p className='text-sm mt-2 text-[#000] font-medium uppercase font-inter'>Chief Executive Officer</p>
            </div>
          </div>
          <div className='lg:w-[25%] ' data-aos='flip-right' data-aos-duration='1500'>
            <img src='/assets/Testimonials/Group 1 (28).png'></img>
            <div className='flex flex-col gap-3 py-2'>
              <p className='font-bold uppercase text-lg lg:text-2xl'>Annamalai <br className='hidden md:block' /> Palaniappan</p>
              <img src='/assets/Gradient-Line-768x1.png.png'></img>
              <p className='text-sm mt-2 text-[#000] font-medium uppercase font-inter'>Chief Operating Officer</p>
            </div>
          </div>
          <div className='lg:w-[25%] ' data-aos='flip-right' data-aos-duration='1500'>
            <img src='/assets/Testimonials/Group 2 (1).png'></img>
            <div className='flex flex-col gap-3 py-2'>
              <p className='font-bold uppercase text-lg lg:text-2xl'>Anup <br className='hidden md:block' />Kumar</p>
              <img src='/assets/Gradient-Line-768x1.png.png'></img>
              <p className='text-sm mt-2 text-[#000] font-medium uppercase font-inter'>Chief Technology Officer</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

