import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Styles/App.css";

export default function SliderInnovation() {
  const settings1 = {
    infinite: true,
    speed: 500,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="hidden lg:block">
        <Slider {...settings1}>
          <div className="SLIDE-1 pb-20">
            <h1 className="tracking-widest text-center text-white text-4xl uppercase font-medium">
              Innovation Creators
            </h1>
            <div className="w-[40%] mx-auto">
              <div>
                <img
                  className="animate-moveSmaller lg:animate-moveLarger"
                  src="/assets/Subtract (1).png"
                ></img>
              </div>
              <p className="border-b border-white/50"></p>
            </div>
            <p className="mx-auto text-center mt-10">
              Bring your ideas to life with FABC Labs, from tokenization to{" "}
              <br /> global market launch.
            </p>

            <div className="flex justify-center">
              <div className="w-[70%] flex justify-between mt-20">
                <div className="relative flex justify-center items-center">
                  {/* Rotating Text Section */}
                  <div className="absolute rounded-full  ">
                    <div className="flex justify-center items-center min-h-screen">
                      <div className="animate-spin  rounded-full">
                        <svg className="circle-svg" viewBox="0 0 450 450">
                          {/* Circle Path */}
                          <path
                            id="circle"
                            d="M 225, 225 m -190, 0 a 190,190 0 1,1 380,0 a 190,190 0 1,1 -380,0"
                          ></path>

                          {/* Text on Circle Path */}
                          <text
                            className="text"
                            textAnchor="middle"
                            fill="white"
                          >
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="25%"
                            >
                              Idea Submission
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="60%"
                            >
                              Tokenization
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="90%"
                            >
                              Incubation and Development
                            </textPath>
                          </text>
                          <circle cx="408" cy="155" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 400, 140 q 10 20 11 25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="258" cy="415" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 275, 412 q -20 7 -25 7" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="60" cy="125" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 50, 140 q 7 -15 15 -25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}
                          {/* Arrowhead Marker Definition */}
                          <defs>
                            <marker
                              id="arrowhead"
                              markerWidth="7"
                              markerHeight="7"
                              refX="0"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon points="0 0, 5 3.5, 0 7" fill="white" />
                            </marker>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Center Stable Logo */}
                  <div className="z-10">
                    <img
                      src="/assets/Group 1597883376 (2).png"
                      alt="Center Logo"
                      className="w-80 animate-beat"
                    />
                  </div>
                </div>
                <div className="relative flex justify-center items-center">
                  {/* Rotating Text Section */}
                  <div className="absolute rounded-full  ">
                    <div className="flex justify-center items-center min-h-screen">
                      <div className="animate-spin  rounded-full">
                        <svg className="circle-svg" viewBox="0 0 450 450">
                          {/* Circle Path */}
                          <path
                            id="circle"
                            d="M 225, 225 m -190, 0 a 190,190 0 1,1 380,0 a 190,190 0 1,1 -380,0"
                          ></path>

                          {/* Text on Circle Path */}
                          <text
                            className="text"
                            textAnchor="middle"
                            fill="white"
                          >
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="25%"
                            >
                              Showcasing & Funding
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="60%"
                            >
                              Launch and Scaling
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="90%"
                            >
                              Global Research & Investment
                            </textPath>
                          </text>
                          <circle cx="408" cy="155" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 400, 140 q 10 20 11 25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="258" cy="415" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 275, 412 q -20 7 -25 7" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="60" cy="125" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 50, 140 q 7 -15 15 -25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}
                          {/* Arrowhead Marker Definition */}
                          <defs>
                            <marker
                              id="arrowhead"
                              markerWidth="7"
                              markerHeight="7"
                              refX="0"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon points="0 0, 5 3.5, 0 7" fill="white" />
                            </marker>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Center Stable Logo */}
                  <div className="z-10">
                    <img
                      src="/assets/Group 1597883376 (2).png"
                      alt="Center Logo"
                      className="w-80 animate-beat"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="SLIDE-2">
            <h1 className="tracking-widest text-center text-white text-4xl uppercase font-medium">
              Innovation Investors
            </h1>
            <div className="w-[40%] mx-auto">
              <div>
                <img
                  className="animate-moveSmaller lg:animate-moveLarger"
                  src="/assets/Subtract (1).png"
                ></img>
              </div>
              <p className="border-b border-white/50"></p>
            </div>
            <p className="mx-auto text-center mt-10">
              Invest in cutting-edge, tokenized Deep Tech projects with <br />{" "}
              high-growth potential.
            </p>

            <div className="flex justify-center">
              <div className="w-[70%] flex justify-between mt-20">
                <div className="relative flex justify-center items-center">
                  {/* Rotating Text Section */}
                  <div className="absolute rounded-full  ">
                    <div className="flex justify-center items-center min-h-screen">
                      <div className="animate-spin  rounded-full">
                        <svg className="circle-svg" viewBox="0 0 450 450">
                          {/* Circle Path */}
                          <path
                            id="circle"
                            d="M 225, 225 m -190, 0 a 190,190 0 1,1 380,0 a 190,190 0 1,1 -380,0"
                          ></path>

                          {/* Text on Circle Path */}
                          <text
                            className="text"
                            textAnchor="middle"
                            fill="white"
                          >
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="25%"
                            >
                              Discover Oppurtunities
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="60%"
                            >
                              Tokenized Investment
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="90%"
                            >
                              Risk Mitigation & Returns
                            </textPath>
                          </text>
                          <circle cx="408" cy="155" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 400, 140 q 10 20 11 25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="258" cy="415" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 275, 412 q -20 7 -25 7" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="60" cy="125" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 50, 140 q 7 -15 15 -25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}
                          {/* Arrowhead Marker Definition */}
                          <defs>
                            <marker
                              id="arrowhead"
                              markerWidth="7"
                              markerHeight="7"
                              refX="0"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon points="0 0, 5 3.5, 0 7" fill="white" />
                            </marker>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Center Stable Logo */}
                  <div className="z-10">
                    <img
                      src="/assets/Group 1597883376 (2).png"
                      alt="Center Logo"
                      className="w-80 animate-beat"
                    />
                  </div>
                </div>
                <div className="relative flex justify-center items-center">
                  {/* Rotating Text Section */}
                  <div className="absolute rounded-full  ">
                    <div className="flex justify-center items-center min-h-screen">
                      <div className="animate-spin  rounded-full">
                        <svg className="circle-svg" viewBox="0 0 450 450">
                          {/* Circle Path */}
                          <path
                            id="circle"
                            d="M 225, 225 m -190, 0 a 190,190 0 1,1 380,0 a 190,190 0 1,1 -380,0"
                          ></path>

                          {/* Text on Circle Path */}
                          <text
                            className="text"
                            textAnchor="middle"
                            fill="white"
                          >
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="25%"
                            >
                              Global Innovation
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="60%"
                            >
                              Launch and Scaling
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="90%"
                            >
                              Growth & Returns
                            </textPath>
                          </text>
                          <circle cx="408" cy="155" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 400, 140 q 10 20 11 25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="258" cy="415" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 275, 412 q -20 7 -25 7" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="60" cy="125" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 50, 140 q 7 -15 15 -25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}
                          {/* Arrowhead Marker Definition */}
                          <defs>
                            <marker
                              id="arrowhead"
                              markerWidth="7"
                              markerHeight="7"
                              refX="0"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon points="0 0, 5 3.5, 0 7" fill="white" />
                            </marker>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Center Stable Logo */}
                  <div className="z-10">
                    <img
                      src="/assets/Group 1597883376 (2).png"
                      alt="Center Logo"
                      className="w-80 animate-beat"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="SLIDE-3">
            <h1 className="tracking-widest text-center text-white text-4xl uppercase font-medium">
              Enterprises
            </h1>
            <div className="w-[40%] mx-auto">
              <div>
                <img
                  className="animate-moveSmaller lg:animate-moveLarger"
                  src="/assets/Subtract (1).png"
                ></img>
              </div>
              <p className="border-b border-white/50"></p>
            </div>
            <p className="mx-auto text-center mt-10">
              Access tailored Deep Tech solutions to scale your business <br />
              and expand into new markets.
            </p>

            <div className="flex justify-center">
              <div className="w-[70%] flex justify-between mt-20">
                <div className="relative flex justify-center items-center">
                  {/* Rotating Text Section */}
                  <div className="absolute rounded-full  ">
                    <div className="flex justify-center items-center min-h-screen">
                      <div className="animate-spin  rounded-full">
                        <svg className="circle-svg" viewBox="0 0 450 450">
                          {/* Circle Path */}
                          <path
                            id="circle"
                            d="M 225, 225 m -190, 0 a 190,190 0 1,1 380,0 a 190,190 0 1,1 -380,0"
                          ></path>

                          {/* Text on Circle Path */}
                          <text
                            className="text"
                            textAnchor="middle"
                            fill="white"
                          >
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="25%"
                            >
                              Customized Solutions
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="60%"
                            >
                              White Label Solutions
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="90%"
                            >
                              R&D Stratergy
                            </textPath>
                          </text>
                          <circle cx="408" cy="155" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 400, 140 q 10 20 11 25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="258" cy="415" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 275, 412 q -20 7 -25 7" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="60" cy="125" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 50, 140 q 7 -15 15 -25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}
                          {/* Arrowhead Marker Definition */}
                          <defs>
                            <marker
                              id="arrowhead"
                              markerWidth="7"
                              markerHeight="7"
                              refX="0"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon points="0 0, 5 3.5, 0 7" fill="white" />
                            </marker>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Center Stable Logo */}
                  <div className="z-10">
                    <img
                      src="/assets/Group 1597883376 (2).png"
                      alt="Center Logo"
                      className="w-80 animate-beat"
                    />
                  </div>
                </div>
                <div className="relative flex justify-center items-center">
                  {/* Rotating Text Section */}
                  <div className="absolute rounded-full  ">
                    <div className="flex justify-center items-center min-h-screen">
                      <div className="animate-spin  rounded-full">
                        <svg className="circle-svg" viewBox="0 0 450 450">
                          {/* Circle Path */}
                          <path
                            id="circle"
                            d="M 225, 225 m -190, 0 a 190,190 0 1,1 380,0 a 190,190 0 1,1 -380,0"
                          ></path>

                          {/* Text on Circle Path */}
                          <text
                            className="text"
                            textAnchor="middle"
                            fill="white"
                          >
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="25%"
                            >
                              Scaling & Global Research
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="60%"
                            >
                              WEB3 Marketing
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="90%"
                            >
                              Growth & Returns
                            </textPath>
                          </text>
                          <circle cx="408" cy="155" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 400, 140 q 10 20 11 25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="258" cy="415" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 275, 412 q -20 7 -25 7" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="60" cy="125" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 50, 140 q 7 -15 15 -25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}
                          {/* Arrowhead Marker Definition */}
                          <defs>
                            <marker
                              id="arrowhead"
                              markerWidth="7"
                              markerHeight="7"
                              refX="0"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon points="0 0, 5 3.5, 0 7" fill="white" />
                            </marker>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Center Stable Logo */}
                  <div className="z-10">
                    <img
                      src="/assets/Group 1597883376 (2).png"
                      alt="Center Logo"
                      className="w-80 animate-beat"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <div className="block lg:hidden">
        <Slider className="" {...settings1}>
          <div className="SLIDE-1 pb-20">
            <h1 className="tracking-widest text-center text-white text-4xl uppercase font-medium">
              Innovation Creators
            </h1>
            <div className="w-[40%] mx-auto">
              <div>
                <img
                  className="animate-moveSmaller lg:animate-moveLarger"
                  src="/assets/Subtract (1).png"
                ></img>
              </div>
              <p className="border-b border-white/50"></p>
            </div>
            <p className="mx-auto text-center mt-10">
              Bring your ideas to life with FABC Labs, from tokenization to{" "}
              <br /> global market launch.
            </p>

            <div className="flex justify-center">
              <div className="lg:w-[70%] flex flex-col gap-24 lg:flex-row justify-between lg:mt-20 mt-20">
                <div className="relative flex justify-center items-center">
                  {/* Rotating Text Section */}
                  <div className="absolute rounded-full  ">
                    <div className="flex justify-center items-center min-h-screen">
                      <div className="animate-spin  rounded-full">
                        <svg className="circle-svg" viewBox="0 0 450 450">
                          {/* Circle Path */}
                          <path
                            id="circle"
                            d="M 225, 225 m -190, 0 a 190,190 0 1,1 380,0 a 190,190 0 1,1 -380,0"
                          ></path>

                          {/* Text on Circle Path */}
                          <text
                            className="text"
                            textAnchor="middle"
                            fill="white"
                          >
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="25%"
                            >
                              Idea Submission
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="60%"
                            >
                              Tokenization
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="90%"
                            >
                              Incubation and Development
                            </textPath>
                          </text>
                          <circle cx="408" cy="155" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 400, 140 q 10 20 11 25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="258" cy="415" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 275, 412 q -20 7 -25 7" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="60" cy="125" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 50, 140 q 7 -15 15 -25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}
                          {/* Arrowhead Marker Definition */}
                          <defs>
                            <marker
                              id="arrowhead"
                              markerWidth="7"
                              markerHeight="7"
                              refX="0"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon points="0 0, 5 3.5, 0 7" fill="white" />
                            </marker>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Center Stable Logo */}
                  <div className="z-10">
                    <img
                      src="/assets/Group 1597883376 (2).png"
                      alt="Center Logo"
                      className="w-56 lg:w-80 animate-beat"
                    />
                  </div>
                </div>
                <div className="relative flex justify-center items-center">
                  {/* Rotating Text Section */}
                  <div className="absolute rounded-full  ">
                    <div className="flex justify-center items-center min-h-screen">
                      <div className="animate-spin  rounded-full">
                        <svg className="circle-svg" viewBox="0 0 450 450">
                          {/* Circle Path */}
                          <path
                            id="circle"
                            d="M 225, 225 m -190, 0 a 190,190 0 1,1 380,0 a 190,190 0 1,1 -380,0"
                          ></path>

                          {/* Text on Circle Path */}
                          <text
                            className="text"
                            textAnchor="middle"
                            fill="white"
                          >
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="25%"
                            >
                              Showcasing & Funding
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="60%"
                            >
                              Launch and Scaling
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="90%"
                            >
                              Global Research & Investment
                            </textPath>
                          </text>
                          <circle cx="408" cy="155" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 400, 140 q 10 20 11 25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="258" cy="415" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 275, 412 q -20 7 -25 7" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="60" cy="125" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 50, 140 q 7 -15 15 -25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}
                          {/* Arrowhead Marker Definition */}
                          <defs>
                            <marker
                              id="arrowhead"
                              markerWidth="7"
                              markerHeight="7"
                              refX="0"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon points="0 0, 5 3.5, 0 7" fill="white" />
                            </marker>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Center Stable Logo */}
                  <div className="z-10">
                    <img
                      src="/assets/Group 1597883376 (2).png"
                      alt="Center Logo"
                      className="w-56 lg:w-80 animate-beat"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="SLIDE-2">
            <h1 className="tracking-widest text-center text-white text-4xl uppercase font-medium">
              Innovation Investors
            </h1>
            <div className="w-[40%] mx-auto">
              <div>
                <img
                  className="animate-moveSmaller lg:animate-moveLarger"
                  src="/assets/Subtract (1).png"
                ></img>
              </div>
              <p className="border-b border-white/50"></p>
            </div>
            <p className="mx-auto text-center mt-10">
              Invest in cutting-edge, tokenized Deep Tech projects with <br />{" "}
              high-growth potential.
            </p>

            <div className="flex justify-center">
              <div className="lg:w-[70%] flex flex-col gap-24 lg:flex-row justify-between lg:mt-20 mt-20">
                <div className="relative flex justify-center items-center">
                  {/* Rotating Text Section */}
                  <div className="absolute rounded-full  ">
                    <div className="flex justify-center items-center min-h-screen">
                      <div className="animate-spin  rounded-full">
                        <svg className="circle-svg" viewBox="0 0 450 450">
                          {/* Circle Path */}
                          <path
                            id="circle"
                            d="M 225, 225 m -190, 0 a 190,190 0 1,1 380,0 a 190,190 0 1,1 -380,0"
                          ></path>

                          {/* Text on Circle Path */}
                          <text
                            className="text"
                            textAnchor="middle"
                            fill="white"
                          >
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="25%"
                            >
                              Discover Oppurtunities
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="60%"
                            >
                              Tokenized Investment
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="90%"
                            >
                              Risk Mitigation & Returns
                            </textPath>
                          </text>
                          <circle cx="408" cy="155" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 400, 140 q 10 20 11 25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="258" cy="415" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 275, 412 q -20 7 -25 7" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="60" cy="125" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 50, 140 q 7 -15 15 -25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}
                          {/* Arrowhead Marker Definition */}
                          <defs>
                            <marker
                              id="arrowhead"
                              markerWidth="7"
                              markerHeight="7"
                              refX="0"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon points="0 0, 5 3.5, 0 7" fill="white" />
                            </marker>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Center Stable Logo */}
                  <div className="z-10">
                    <img
                      src="/assets/Group 1597883376 (2).png"
                      alt="Center Logo"
                      className="w-56 lg:w-80 animate-beat"
                    />
                  </div>
                </div>
                <div className="relative flex justify-center items-center">
                  {/* Rotating Text Section */}
                  <div className="absolute rounded-full  ">
                    <div className="flex justify-center items-center min-h-screen">
                      <div className="animate-spin  rounded-full">
                        <svg className="circle-svg" viewBox="0 0 450 450">
                          {/* Circle Path */}
                          <path
                            id="circle"
                            d="M 225, 225 m -190, 0 a 190,190 0 1,1 380,0 a 190,190 0 1,1 -380,0"
                          ></path>

                          {/* Text on Circle Path */}
                          <text
                            className="text"
                            textAnchor="middle"
                            fill="white"
                          >
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="25%"
                            >
                              Global Innovation
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="60%"
                            >
                              Launch and Scaling
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="90%"
                            >
                              Growth & Returns
                            </textPath>
                          </text>
                          <circle cx="408" cy="155" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 400, 140 q 10 20 11 25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="258" cy="415" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 275, 412 q -20 7 -25 7" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="60" cy="125" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 50, 140 q 7 -15 15 -25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}
                          {/* Arrowhead Marker Definition */}
                          <defs>
                            <marker
                              id="arrowhead"
                              markerWidth="7"
                              markerHeight="7"
                              refX="0"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon points="0 0, 5 3.5, 0 7" fill="white" />
                            </marker>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Center Stable Logo */}
                  <div className="z-10">
                    <img
                      src="/assets/Group 1597883376 (2).png"
                      alt="Center Logo"
                      className="w-56 lg:w-80 animate-beat"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="SLIDE-3">
            <h1 className="tracking-widest text-center text-white text-4xl uppercase font-medium">
              Enterprises
            </h1>
            <div className="w-[40%] mx-auto">
              <div>
                <img
                  className="animate-moveSmaller lg:animate-moveLarger"
                  src="/assets/Subtract (1).png"
                ></img>
              </div>
              <p className="border-b border-white/50"></p>
            </div>
            <p className="mx-auto text-center mt-10">
              Access tailored Deep Tech solutions to scale your business <br />
              and expand into new markets.
            </p>

            <div className="flex justify-center">
              <div className="lg:w-[70%] flex flex-col gap-24 lg:flex-row justify-between lg:mt-20 mt-20">
                <div className="relative flex justify-center items-center">
                  {/* Rotating Text Section */}
                  <div className="absolute rounded-full  ">
                    <div className="flex justify-center items-center min-h-screen">
                      <div className="animate-spin  rounded-full">
                        <svg className="circle-svg" viewBox="0 0 450 450">
                          {/* Circle Path */}
                          <path
                            id="circle"
                            d="M 225, 225 m -190, 0 a 190,190 0 1,1 380,0 a 190,190 0 1,1 -380,0"
                          ></path>

                          {/* Text on Circle Path */}
                          <text
                            className="text"
                            textAnchor="middle"
                            fill="white"
                          >
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="25%"
                            >
                              Customized Solutions
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="60%"
                            >
                              White Label Solutions
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="90%"
                            >
                              R&D Stratergy
                            </textPath>
                          </text>
                          <circle cx="408" cy="155" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 400, 140 q 10 20 11 25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="258" cy="415" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 275, 412 q -20 7 -25 7" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="60" cy="125" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 50, 140 q 7 -15 15 -25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}
                          {/* Arrowhead Marker Definition */}
                          <defs>
                            <marker
                              id="arrowhead"
                              markerWidth="7"
                              markerHeight="7"
                              refX="0"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon points="0 0, 5 3.5, 0 7" fill="white" />
                            </marker>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Center Stable Logo */}
                  <div className="z-10">
                    <img
                      src="/assets/Group 1597883376 (2).png"
                      alt="Center Logo"
                      className="w-56 lg:w-80 animate-beat"
                    />
                  </div>
                </div>
                <div className="relative flex justify-center items-center">
                  {/* Rotating Text Section */}
                  <div className="absolute rounded-full  ">
                    <div className="flex justify-center items-center min-h-screen">
                      <div className="animate-spin  rounded-full">
                        <svg className="circle-svg" viewBox="0 0 450 450">
                          {/* Circle Path */}
                          <path
                            id="circle"
                            d="M 225, 225 m -190, 0 a 190,190 0 1,1 380,0 a 190,190 0 1,1 -380,0"
                          ></path>

                          {/* Text on Circle Path */}
                          <text
                            className="text"
                            textAnchor="middle"
                            fill="white"
                          >
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="25%"
                            >
                              Scaling & Global Research
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="60%"
                            >
                              WEB3 Marketing
                            </textPath>
                            <textPath
                              className="text__path font-poppins"
                              href="#circle"
                              startOffset="90%"
                            >
                              Growth & Returns
                            </textPath>
                          </text>
                          <circle cx="408" cy="155" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 400, 140 q 10 20 11 25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="258" cy="415" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 275, 412 q -20 7 -25 7" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}

                          <circle cx="60" cy="125" r="25" stroke="white"
                            stroke-width="1" fill='transparent'></circle>
                          <path d="M 50, 140 q 7 -15 15 -25" fill="transparent" stroke="white" strokeWidth="2" markerEnd="url(#arrowhead)" /> {/* Main Stroke */}
                          {/* Arrowhead Marker Definition */}
                          <defs>
                            <marker
                              id="arrowhead"
                              markerWidth="7"
                              markerHeight="7"
                              refX="0"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon points="0 0, 5 3.5, 0 7" fill="white" />
                            </marker>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Center Stable Logo */}
                  <div className="z-10">
                    <img
                      src="/assets/Group 1597883376 (2).png"
                      alt="Center Logo"
                      className="w-56 lg:w-80 animate-beat"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <style>
        {`
                .slick-dots li.slick-active button::before {
                  color: white !important; 
                  font-size: 12px  !important;
                  opacity: 1;
                }
                `}
      </style>
    </div>
  );
}
