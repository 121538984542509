import React from 'react'
import Header from '../Layout/Header'
import Footer2 from '../Layout/Footer2'

export default function ContactUs() {
  return (
    <>
    <Header/>
    <div className="font-inter w-full bg-cover bg-center min-h-screen bg-no-repeat text-white relative bg-blue-900" >
        <div className="bg-gradient-to-r from-[#185CC2D9] via-[#0A2973D9] to-[#0E183700] min-h-screen">
            <div className="bg-gradient-to-b from-blue-900 via-blue-900 to-[#90752c] min-h-screen">
                <div className="container mx-auto pt-20">
                    <div className="lg:w-[75%] sm:w-[90%] w-full mx-auto text-center">
                        <h2 className="font-bold text-center uppercase lg:text-2xl text-xl py-2">Get in Touch</h2>
                        
                        <p className="text-center uppercase py-4 text-[#E8BA3C] tracking-wider sm:text-lg text-base">We'd love to hear from you. Let's discuss how we can help you achieve your goals.</p>
                    </div>
                  <div className='flex justify-center'>
                    <div className='lg:w-[40%] bg-white rounded-lg py-4 mt-10 lg:mt-0'>
                        <div>
                          <p className='font-roboto text-black text-xl text-center font-bold'>CONTACT FORM</p>
                        </div>
                        <div className='flex justify-center mt-3'>
                        <div className='w-[80%] flex flex-col gap-5'>
                         <input className='outline-none border rounded-lg border-black w-full text-black px-3 py-2' placeholder='Name'></input>
                         <input className='outline-none border rounded-lg border-black w-full text-black px-3 py-2' placeholder='Email'></input>
                         <input className='outline-none border rounded-lg border-black w-full text-black px-3 py-2' placeholder='Subject'></input>
                         <textarea className='w-full border text-black border-black rounded-lg px-3' placeholder='message '></textarea>
                        </div>
                        </div>
                        <div className='flex justify-center mt-4'>
                        <button className=" bg-gradient-to-r from-[#4F93FF] via-[#4F93FF] to-[#4F93FF] border border-[#4CC5E2] text-white font-bold text-lg py-2 px-12 border-b-[#E8BA3C] border-b-4 uppercase">SUBMIT</button>
                        </div>
                    </div>
                  </div>
                </div>
                <div className='mt-40'>
                <Footer2/>
                </div>
            </div>
        </div>
        
    </div>
</>
  )
}
