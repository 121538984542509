import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faPlay } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
import WhyLabs from '../Slider/WhyLabs';
import SliderInnovation from '../Slider/SliderInnovation';
import SliderSuccess from '../Slider/SliderSuccess';
import SliderAbout from '../Slider/SliderAbout';
import SliderAboutMobile from '../Slider/SliderAboutMobile';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';


export default function Landing() {

  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3); 
  const [showFirstTwoSlides, setShowFirstTwoSlides] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    try {
      const response = await axios.post('https://fabclabs.global/api/form', formData);
      console.log(response);
      toast.success('Form submitted successfully!');
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit form. Please try again.');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstTwoSlides((prev) => !prev);  
    }, 10000); 

    return () => clearInterval(interval); 
  }, []);


  const totalSlides = 5;

  const calculateCenterSlide = () => {
    const middleSlide = Math.floor(settings.slidesToShow / 2);
    return (currentSlide + middleSlide) % totalSlides;
  };
  const updateSlidesToShow = () => {
    if (window.innerWidth <= 640) {
      setSlidesToShow(1);
    } else if (window.innerWidth <= 1024) {
      setSlidesToShow(2);
    } else {
      setSlidesToShow(3);
    }
  };
  useEffect(() => {
    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);
    return () => window.removeEventListener("resize", updateSlidesToShow);
  }, []);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCurrentSlide(newIndex);
  };



  const settings = {
    infinite: true,
    speed: 500,
    dots: true,
    slidesToShow,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className='font-inter text-white'>
    <Toaster /> 

      <div className='z-50'>
        <Header />
      </div>
      <div className='  Main-container Desktop-screen overflow-hidden'>
        <div className='Section-1'>
        <div className='hidden lg:block'>
          <div className='  flex items-center justify-center relative'>
            <video
              autoPlay
              loop
              muted
              playsinline
              className=" z-0 h-full w-full"
            >
              <source src="/assets/BG/Main Banner BG.mp4" type="video/mp4" />
            </video>
            <div className='flex items-end justify-center bg-gradient-to-b from-[#0e1837]/5 via-[#0e1837]/25 to-[#0e1837] absolute h-full w-full py-10'>
              <div className='w-[80%] mt-36'>
                <div className=''>
                  <div className='flex justify-center'>
                    <div className='w-[98%] border bg-[#191c21] bg-opacity-80 py-8 px-2'>
                      <div className='flex justify-between -translate-y-10'>
                        <img className='w-[1%] animate-moveRight' src='/assets/Rectangle 51.png'></img>
                        <div className='translate-x-7'>
                          <img className='w-[50%] animate-moveUp ' src='/assets/Rectangle 51.png'></img>
                        </div>
                      </div>
                      <p className='font-jura text-white text-5xl text-center font-bold text-shadow-custom uppercase'>The Leading Innovation Hub for Tokenizing Revolutionary Ideas
                      </p>
                      <div className='flex justify-between translate-y-10'>
                        <div className='-translate-x-4'>
                          <img className='w-[50%] animate-moveDown' src='/assets/Rectangle 51.png'></img>
                        </div>
                        <img className='w-[1%] animate-moveLeft' src='/assets/Rectangle 51.png'></img>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-center'>
                  <div className='w-[80%] mt-5'>
                    <p className='font-inter text-white text-center text-3xl'>Tokenize, Incubate, and Launch Innovative Ideas into IPs with Deep Tech Solutions</p>
                  </div>
                </div>
                <div className='flex justify-center mt-5'>
                <NavLink onClick={() => scrollToSection('products')}  to="/"  className="relative overflow-hidden text-white px-5 py-3 bg-gradient-to-br from-blue-600 via-blue-800 to-[#E8BA3C80] font-bold uppercase tracking-wider border border-blue-400  shadow-md">
                    Build your Product
                    <span className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500"></span>
                 </NavLink>
                </div>
                <div className='flex justify-center translate-y-20 z-10'>
                  <img className='fa-bounce' src='/assets/Icon/Group 1597883259.png'></img>
                </div>
                <div className='hidden'>
                  <img src='/assets/Downarrow.png' className='w-12 absolute left-[50%] -translate-x-[50%] z-10 -bottom-5'></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='lg:hidden block'>
        <div className='Section-1  justify-center'>
        <video
          autoPlay
          loop
          muted
          playsinline
          className=" z-0 h-full  "
        >
          <source src="/assets/Main Banner BG.mp4" type="video/mp4" />
        </video>
        <div className='flex items-end justify-center bg-[#0e1837]   z-20  w-full py-10'>
          <div className='w-[80%] '>
            <div className=''>

        <div className='flex justify-center -translate-y-16'>
            <img className='w-2/12 fa-bounce' src='/assets/Group 1597883259.png'></img>
            </div>

              <div className='flex justify-center'>
                <div className='w-[98%] border-[#fff] border-2  py-8 bg-gradient-to-br from-[#0d2262] via-[#0d2236] to-[#E8BA3C80] ' >
                <img src=''></img>
                  <div className='flex justify-between -translate-y-10'>
                    <img className='w-[3%] animate-moveRighter' src='/assets/Rectangle 51.png'></img>
                    <div className='translate-x-4'>
                      <img className='w-[80%] animate-moveUpper ' src='/assets/Rectangle 51.png'></img>
                    </div>
                  </div>
                  <p className='font-play text-white text-2xl text-center '>Invest in Your Vi<span className='text-[#E8BA3C] font-play '>sion</span> <br></br>
                    <span className='text-[#E8BA3C] font-play '><span className='text-white font-play'>with leading </span>innovation <span className='text-white font-play'>hub wit</span>h web 3 tech</span></p>
                  <div className='flex justify-between translate-y-10'>
                    <div className='-translate-x-3'>
                      <img className='w-[80%] animate-moveDowner' src='/assets/Rectangle 51.png'></img>
                    </div>
                    <img className='w-[3%] animate-moveLefter' src='/assets/Rectangle 51.png'></img>
                  </div>
                </div>
              </div>



            </div>
            <div className='flex justify-center'>
              <div className='w-[70%] '>
                <p className='font-inter text-white text-center lg:text-3xl mt-5 lg:mt-0'>We transform ideas into market. Ready products, Embrace the Future</p>
              </div>
            </div>
            <div className='flex justify-center mt-5'>
              <button className="relative overflow-hidden text-white px-5 py-3 bg-gradient-to-br from-blue-600 via-blue-800 to-[#E8BA3C80] font-bold uppercase tracking-wider border border-blue-400  shadow-md">
                LEARN MORE
                <span className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500"></span>
              </button>
            </div>
            
          </div>

        </div>

      </div>
        </div>
        </div>
        <div className=' Section-2  flex items-center justify-center py-20 bg-gradient-to-r from-[#080820] via-[#0f103e] to-[#080820]' id='about'>
          
          <div className='flex justify-center items-center bg-gradient-to-r from-[#080820] via-[#0f103e] to-[#080820]    w-full pb-10 pt-10'>
            <div className='w-[90%] lg:w-[80%] flex flex-col lg:flex-row items-center justify-center pt-5 bg-[#0a2973] bg-center bg-no-repeat pb-10' style={{ backgroundImage: "url('')" }}>
              <div className='w-[100%] lg:w-[40%] lg:-translate-x-10'>
                <div className='border-[5px] border-[#144ead]  lg:w-[85%] l'>
                <div className='lg:block hidden'>
                  <SliderAbout/>
                  </div>
                  <div className='lg:hidden block'>
                   <SliderAboutMobile/>
                  </div>
                </div>
              </div>
              <div className='lg:w-[60%] lg:-translate-x-10 mt-5 lg:mt-0'>
                <div className='flex justify-center'>
                  <img src='/assets/Ellipse 3.png' className='w-[5%]'></img>
                </div>
                <div>
                  <p className='text-white font-inter font-bold text-3xl text-center 2xl:pt-5 xl:pt-5'>A&nbsp;&nbsp;&nbsp;B&nbsp;&nbsp;&nbsp;O&nbsp;&nbsp;&nbsp;U&nbsp;&nbsp;&nbsp;T&nbsp;&nbsp;&nbsp; U&nbsp;&nbsp;&nbsp;S</p>
                  <div className='flex justify-center'>
                    <div className='w-[80%]'>
                      <img className='animate-moveSmall lg:animate-moveLarge' src='/assets/Subtract (1).png'></img>
                    </div>
                  </div>
                  <div className='flex justify-center'>
                    <p className='border border-b w-[80%]'></p>
                  </div>
                </div>
                <div>
                  <p className='lg:text-left text-center text-white font-inter font-bold text-2xl mt-5 px-4'>A Complete Ecosystem for Innovators, Investors and Enterprises</p>
                   <div className='flex flex-col gap-10 py-5 px-4'>
                  <p className='font-inter text-white lg:text-xl font-light lg:text-left text-center mt-5'>At FABC Labs, our mission is to tokenize, incubate, and launch the most forward-thinking ideas. We foster a collaborative environment where innovators are empowered to bring their ideas to life, investors can fuel the future of technology, and enterprises can leverage scalable solutions for long-term success.</p>
                  <p className='font-inter text-white lg:text-xl font-light lg:text-left text-center'>Whether you're an individual innovator, a forward-thinking investor, or a business seeking the next big opportunity, FABC Labs is your partner in Deep Tech innovation.</p>
                 </div>
                 </div>

              </div>
            </div>
          </div>
        </div>
        <div className=' Section-3 flex items-center min-h-screen bg-gradient-to-t from-[#185CC2] via-[#0A2973] to-[#0E1837] py-10' id='products'>
          <div className='w-full py-10'>
            <div>
              <div className='flex justify-center'>
                <p className='text-white font-inter text-center text-2xl md:text-4xl uppercase '>O&nbsp;u&nbsp;r&nbsp;&nbsp; F&nbsp;l&nbsp;a&nbsp;g&nbsp;s&nbsp;h&nbsp;i&nbsp;p <br></br>P&nbsp;r&nbsp;o&nbsp;d&nbsp;u&nbsp;c&nbsp;t&nbsp;s&nbsp;</p>
              </div>
              <div className='flex justify-center'>
                <div className='w-[90%] md:w-[30%]'>
                  <img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img>
                </div>
              </div>
              <div className='flex justify-center'>
                <p className='border border-b w-[90%] md:w-[30%]'></p>
              </div>
            </div>
            <div className='mt-10'>
              <Slider
                {...settings}
                beforeChange={handleBeforeChange}
                className="w-[80%] mx-auto text-center"
              >
                {['Product2', 'Product1', 'Product3', 'Product4', 'Product5'].map((product, index) => (
                  <div key={index} className={`px-5 lg:px-2 h-[33rem]`}>
                    <div className={`border-[2px] transition-transform duration-500 translate-y-6 h-[30rem] ${calculateCenterSlide() === index ? 'scale-110 bg-gradient-to-b from-[#295999] to-[#29256F] border-[#E8BA3C]' : 'border-[#0194FE] scale-95'} rounded-2xl backdrop-blur-sm w-[100%] py-5 px-5 md:px-7`}>
                      <div className="flex flex-col items-center ">
                        <div>
                          <img src={`/assets/${product}.png`} className='mx-auto' alt={`${product}`} />
                        </div>
                        <div className='relative mt-3'>
                          <h1 className='font-bold  lg:text-xl text-center'>
                            {index === 0 && 'Skills Marketplace'}
                            {index === 1 && 'Decentralized AI BOTs Solutions'}
                            {index === 2 && 'Real World Asset Tokenization'}
                            {index === 3 && 'Track & Trace Chain'}
                            {index === 4 && 'Research & Development'}
                            {index === 5 && 'Skills Marketplace'}
                          </h1>
                          <img src='/assets/Subtract (1).png' className='absolute rotate-90 -right-[1.6rem] lg:-right-[2rem] top-[50%] -translate-y-[50%]' alt="Icon" />
                        </div>
                        <p className="mt-3 text-sm">
                          {index === 0 && 'The Skills Marketplace (SMP) is your gateway to a decentralized platform where innovators, professionals, and businesses come together to exchange knowledge and skills. It\'s all about empowering each other to bring new ideas and technologies to life.'}
                          {index === 1 && 'Our Decentralized AI BOTs streamline processes using AI and Blockchain, enhancing productivity and efficiency. It\'s an innovative approach to automation, tailored for industries looking to stay ahead.'}
                          {index === 2 && 'With Real World Asset Tokenization (RWT), managing and trading physical assets like real estate or commodities becomes simpler and more efficient. Tokenizing these assets unlocks new possibilities in the blockchain economy.'}
                          {index === 3 && 'The Track & Trace Chain (TTC) brings transparency to industries like food and healthcare. With real-time tracking, it’s easier to ensure accountability and trust from origin to delivery.'}
                          {index === 4 && 'Our R&D team pushes boundaries in AI, Blockchain, Quantum Computing, and the Metaverse. By constantly exploring new technologies, we ensure that you stay at the forefront of innovation.'}
                          {index === 5 && 'The Skills Marketplace (SMP) is your gateway to a decentralized platform where innovators, professionals, and businesses come together to exchange knowledge and skills. It\'s all about empowering each other to bring new ideas and technologies to life.'}
                        </p>
                        <div className="flex justify-center mt-5">
                          <NavLink onClick={() => scrollToSection('contact')}  to="/" className="relative overflow-hidden text-sm px-5 py-2 bg-gradient-to-br from-blue-600 via-blue-800 to-[#E8BA3C80] font-bold uppercase tracking-wider border border-blue-400 shadow-md">
                            Learn More
                            <span className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500"></span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <style>
                {`
                .slick-dots li.slick-active button::before {
                  color: white !important; 
                  font-size: 12px  !important;
                  opacity: 1;
                }
                `}
              </style>
            </div>


          </div>
        </div>
        <div className=' Section-4 bg-[#0e0f3d] flex items-center min-h-screen' id='services'>
          <div className='w-full py-20'>
            <h1 className='tracking-widest text-center text-white text-2xl md:text-4xl uppercase font-medium'>Our Expertise</h1>
            <div className='w-[90%] md:w-[30%] mx-auto'>
              <div><img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img></div>
              <p className='border-b border-white/50'></p>
            </div>
            <div className='flex flex-col-reverse md:flex-row w-[90%]  gap-10 md:gap-0 mx-auto mt-10'>
            <div className='w-[100%] lg:w-[30%] grid gap-10 content-center relative h-auto hidden lg:block mt-20' >
              <div >
   <div className={`lg:absolute top-0 left-0 w-full transition-opacity duration-500 flex flex-col gap-16 ${showFirstTwoSlides ? 'opacity-100' : 'opacity-0'}`}>
      <div className='SLIDE-1 bg-[#11418d] rounded-lg p-5 md:px-10 py-7'>
        <h1 className='tracking-widest text-center text-white uppercase font-medium flex justify-center gap-2 items-center'><img src='/assets/Group 1 (31).png'></img>InnoTech <br /> Solutions</h1>
        <div className='w-[75%] mx-auto h-[17px] grid-content-center -translate-y-3'>
          <FontAwesomeIcon icon={faPlay} className='-rotate-90 text-[#E8BA3C] text-xs ' />
          <hr className=' border-t border-white/40 -translate-y-1'></hr>
        </div>
        <p className='font-medium text-center mt-2'>We provide tailored InnoTech Solutions to help businesses scale and thrive. Our expertise spans multiple industries and emerging technologies, ensuring your business stays ahead in the fast-evolving tech landscape.</p>
      </div>
      <div className='SLIDE-2 bg-[#1d45be] rounded-lg p-5 md:px-10 py-7'>
        <h1 className='tracking-widest text-center text-white uppercase font-medium flex justify-center gap-2 items-center'><img src='/assets/Group (5).png'></img>TailorTech <br /> Solutions</h1>
        <div className='w-[75%] mx-auto h-[17px] grid-content-center -translate-y-3'>
          <FontAwesomeIcon icon={faPlay} className='-rotate-90 text-[#E8BA3C] text-xs' />
          <hr className=' border-t border-white/40 -translate-y-1'></hr>
        </div>
        <p className='font-medium text-center mt-2'>Our team delivers tailored InnoTech Solutions in Blockchain, AI, Quantum Computing, and more, from product design to full implementation, ensuring your business stays ahead with cutting-edge technology.</p>
      </div>
      </div>
      <div className={`lg:absolute top-0 left-0 w-full transition-opacity duration-500 flex flex-col gap-16 ${showFirstTwoSlides ? 'opacity-0' : 'opacity-100'}`}>
      <div className='SLIDE-3 bg-[#071f56] rounded-lg p-5 md:px-10 py-7'>
        <h1 className='tracking-widest text-center text-white uppercase font-medium flex justify-center gap-2 items-center'><img src='/assets/Group (6).png'></img>Research & <br></br> Development</h1>
        <div className='w-[75%] mx-auto h-[17px] grid-content-center -translate-y-3'>
          <FontAwesomeIcon icon={faPlay} className='-rotate-90 text-[#E8BA3C] text-xs' />
          <hr className=' border-t border-white/40 -translate-y-1'></hr>
        </div>
        <p className='font-medium text-center mt-2'>Leverage our R&D expertise at FABC Labs to develop or optimize technologies, pushing the boundaries of Deep Tech with the most advanced InnoTech Solutions.</p>
      </div>
      <div className='SLIDE-4 bg-[#1d3670] rounded-lg p-5 md:px-10 py-7'>
        <h1 className='tracking-widest text-center text-white uppercase font-medium flex justify-center gap-2 items-center'><img src='/assets/Vector (12).png'></img>Web3 Digital <br></br> Marketing Services</h1>
        <div className='w-[75%] mx-auto h-[17px] grid-content-center -translate-y-3'>
          <FontAwesomeIcon icon={faPlay} className='-rotate-90 text-[#E8BA3C] text-xs' />
          <hr className=' border-t border-white/40 -translate-y-1'></hr>
        </div>
        <p className='font-medium text-center mt-2'>Our Web3 digital marketing services help you build a strong presence in the Blockchain and crypto space, connecting your business with the right audience to drive engagement and investment.</p>
      </div>
      </div>
              </div>
              </div>
              <div className='block lg:hidden'>
                            <div className='w-[100%] lg:w-[30%] grid gap-10 content-center relative h-auto'>
                 <div className='SLIDE-1 bg-[#133D8E] rounded-lg p-5 md:px-10 py-7'>
                   <h1 className='tracking-widest text-center text-white uppercase font-medium'>InnoTech <br /> Solutions</h1>
                   <div className='w-[75%] mx-auto h-[17px] grid-content-center -translate-y-3'>
                     <FontAwesomeIcon icon={faPlay} className='-rotate-90 text-[#E8BA3C] text-xs ' />
                     <hr className=' border-t border-white/40 -translate-y-1'></hr>
                   </div>
                   <p className='font-medium text-center mt-2'>We provide tailored InnoTech Solutions to help businesses scale and thrive. Our expertise spans multiple industries and emerging technologies, ensuring your business stays ahead in the fast-evolving tech landscape.</p>
                 </div>
                 <div className='SLIDE-2 bg-[#133D8E] rounded-lg p-5 md:px-10 py-7'>
                   <h1 className='tracking-widest text-center text-white uppercase font-medium'>TailorTech <br /> Solutions</h1>
                   <div className='w-[75%] mx-auto h-[17px] grid-content-center -translate-y-3'>
                     <FontAwesomeIcon icon={faPlay} className='-rotate-90 text-[#E8BA3C] text-xs' />
                     <hr className=' border-t border-white/40 -translate-y-1'></hr>
                   </div>
                   <p className='font-medium text-center mt-2'>Our team delivers tailored InnoTech Solutions in Blockchain, AI, Quantum Computing, and more, from product design to full implementation, ensuring your business stays ahead with cutting-edge technology.</p>
                 </div>
                 <div className='SLIDE-3 bg-[#133D8E] rounded-lg p-5 md:px-10 py-7'>
                   <h1 className='tracking-widest text-center text-white uppercase font-medium'>Research & <br></br> Development</h1>
                   <div className='w-[75%] mx-auto h-[17px] grid-content-center -translate-y-3'>
                     <FontAwesomeIcon icon={faPlay} className='-rotate-90 text-[#E8BA3C] text-xs' />
                     <hr className=' border-t border-white/40 -translate-y-1'></hr>
                   </div>
                   <p className='font-medium text-center mt-2'>Leverage our R&D expertise at FABC Labs to develop or optimize technologies, pushing the boundaries of Deep Tech with the most advanced InnoTech Solutions.</p>
                 </div>
                 <div className='SLIDE-4 bg-[#133D8E] rounded-lg p-5 md:px-10 py-7'>
                   <h1 className='tracking-widest text-center text-white uppercase font-medium'>Web3 Digital <br></br> Marketing Services</h1>
                   <div className='w-[75%] mx-auto h-[17px] grid-content-center -translate-y-3'>
                     <FontAwesomeIcon icon={faPlay} className='-rotate-90 text-[#E8BA3C] text-xs' />
                     <hr className=' border-t border-white/40 -translate-y-1'></hr>
                   </div>
                   <p className='font-medium text-center mt-2'>Our Web3 digital marketing services help you build a strong presence in the Blockchain and crypto space, connecting your business with the right audience to drive engagement and investment.</p>
                 </div>
                         </div>
                         </div>

              <div className='w-[100%] lg:w-[70%] flex items-center justify-center'>
                <img src='/assets/expertise.png' className='md:w-[75%]'></img>
              </div>
            </div>
          </div>
        </div>
        <div className=' Section-5  bg-[#0e0f3d] ' id='innovation'>
          <div className='w-full py-20 bg-cover bg-center' style={{ backgroundImage: "url('/assets/innovationbg.png')" }}>
            <SliderInnovation />
          </div>
        </div>
        <div className='Section-6  bg-gradient-to-b from-[#0e1842] via-[#0e296e]  to-[#133d8c] py-10'>
          <div className='py-10  grid content-center text-center'>
            <h1 className='uppercase tracking-widest text-2xl md:text-4xl 2xl:text-5xl font-medium'>Why Fabc Labs</h1>
            <div className='w-[30%] mx-auto'>
              <img className='animate-moveSmall lg:animate-moveLarge' src='/assets/Subtract (1).png'></img>
              <p className='border-b border-white/50'></p>
            </div>
            <p className='w-[90%] mx-auto mt-5 text-lg 2xl:text-2xl'>We are committed to pushing the boundaries of technology, exploring the uncharted, and transforming visionary ideas into tangible solutions.</p>

          </div>
          <div className='py-20'>
            <WhyLabs />
          </div>
        </div>
        <div className='Section-7 bg-[#091c47] py-20 relative min-h-screen'>
          <div>
            <div className='flex justify-center '>
              <p className='text-white font-inter text-center text-2xl md:text-4xl uppercase '>H&nbsp;O&nbsp;W&nbsp;&nbsp; D&nbsp;O&nbsp;  W&nbsp;E&nbsp; s&nbsp;u&nbsp;p&nbsp;p&nbsp;o&nbsp;r&nbsp;t <br></br>I&nbsp;N&nbsp;N&nbsp;O&nbsp;V&nbsp;A&nbsp;T&nbsp;I&nbsp;&nbsp;O&nbsp;N</p>
            </div>
            <div className='flex justify-center '>
              <div className='w-[30%]'>
                <img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img>
              </div>
            </div>
            <div className='flex justify-center'>
              <p className='border border-b w-[30%]'></p>
            </div>
          </div>
          <div className="mt-16">
            <div class="py-3 sm:max-w-xl sm:mx-auto w-full px-2 sm:px-0 relative flex justify-center items-start">

              <div class="z-20 w-full relative  antialiased text-sm ">

                <div class="block hidden lg:block  w-[45%] absolute  left-0 md:left-1/2 transform md:-translate-x-1/2">
                  <img className='' src='/assets/Group 1 (26).png'></img>
                </div>

                <div class="mt-6 sm:mt-0">
                  <div class="flex flex-col sm:flex-row items-center relative">
                    <div class="flex flex-col md:flex-row justify-end w-[90%] md:w-full mx-auto items-center">


                    </div>

                  </div>
                </div>


                <div class="mt-10 md:mt-0 ">
                  <div class="flex flex-col sm:flex-row items-center ">
                    <div class="flex justify-start w-[90%] md:w-full mx-auto items-center">
                      <div class="w-full sm:w-1/2 sm:pr-8">
                        <div>
                          <div className='relative'>
                            <div class="z-20 relative  text-center  rounded-lg ">
                              <p className='text-white text-left font-inter font-bold flex justify-center gap-2 items-center text-2xl lg:text-3xl lg:-translate-x-16'><img className='w-2/12 lg:w-3/12' src='/assets/Image (4).png'></img>Team Structuring <img className='block lg:hidden' src='/assets/Line 116.png'></img></p>
                              <p className='text-white font-roboto mt-4'>FABC Labs helps innovators form co-founding teams and advisory boards, bringing together experts to execute ideas.</p>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="mt-10 md:mt-0 ">
                  <div class="flex flex-col sm:flex-row items-center">
                    <div class="flex justify-end w-[90%] md:w-full mx-auto items-center">
                      <div class="w-full sm:w-1/2 sm:pl-8">
                        <div>
                          <div className='relative'>
                            <div class=" z-20 relative  text-center rounded-lg ">
                              <p className='text-white font-inter font-bold flex justify-center items-center gap-3 text-2xl lg:text-3xl text-left lg:translate-x-24'> <img className='block lg:hidden' src='/assets/Line 117.png'></img><img className='w-2/12 lg:w-3/12' src='/assets/image 10.png'></img>Board Creation</p>
                              <p className='text-white text-left font-roboto mt-4 lg:translate-x-24'>We assist innovators in selecting the right board members, who will guide the project through each development phase.</p>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="mt-10 md:mt-0 ">
                  <div class="flex flex-col sm:flex-row items-center">
                    <div class="flex justify-start w-[90%] md:w-full mx-auto items-center">
                      <div class="w-full sm:w-1/2 sm:pr-8">
                        <div>
                          <div className='relative'>
                            <div class="z-20 relative  text-center  rounded-lg ">
                              <p className='text-white font-inter font-bold flex justify-center text-left items-center gap-2 text-2xl lg:text-3xl lg:-translate-x-20'><img className='w-2/12 lg:w-3/12' src='/assets/image 11 (1).png'></img>Innovation
                                Best Practices <img className='block lg:hidden' src='/assets/Line 116.png'></img></p>
                              <p className='text-white font-roboto mt-4 text-left lg:-translate-x-0'>Providing thought leadership and advice on scaling innovations, structuring businesses, and market entry strategies.</p>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="mt-10 md:mt-0 ">
                  <div class="flex flex-col sm:flex-row items-center">
                    <div class="flex justify-end w-[90%] md:w-full mx-auto items-center">
                      <div class="w-full sm:w-1/2 sm:pl-8">
                        <div>
                          <div className='relative'>
                            <div class=" z-20 relative  text-center rounded-lg ">
                              <p className='text-white font-inter font-bold flex justify-center items-center text-left  text-2xl lg:text-3xl lg:translate-x-24 gap-2 lg:gap-3'><img className='block lg:hidden' src='/assets/Line 117.png'></img> <img className='w-2/12 lg:w-3/12' src='/assets/wallet 1.png'></img>Funding Allocation </p>
                              <p className='text-white font-roboto mt-4 lg:translate-x-24 text-left'>We guide how investments are distributed, ensuring the right amount of funding is set aside for scaling products.</p>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className='flex justify-center mt-5'>
          <NavLink onClick={() => scrollToSection('contact')}  to="/" className="relative overflow-hidden text-white px-5 py-3 bg-gradient-to-br from-blue-600 via-blue-800 to-[#E8BA3C80] font-bold uppercase tracking-wider border border-blue-400  shadow-md">
              Get Expert Guidance
              <span className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500"></span>
            </NavLink>
          </div>
        </div>
        <div className='Section-8 bg-gradient-to-r from-[#185CC2D9]/85 via-[#0A2973D9]/85 to-[#0E183700]/85 py-20'>
          <h1 className='tracking-widest text-center text-white text-2xl lg:text-4xl uppercase font-medium'>Success Stories from <br /> Innovators and Investors</h1>
          <div className='w-[90%] md:w-[50%] mx-auto'>
            <div><img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img></div>
            <p className='border-b border-white/50'></p>
          </div>
          <div className='relative'>
            <div className='lg:px-20'>
              <img className='w-[15%] lg:w-auto absolute -top-10 lg:-top-24 left-7 lg:left-20 z-10' src='/assets/Group 1597883322.png'></img>
            </div>
            <div className=' mt-20'>
              <SliderSuccess />
            </div>
          </div>
        </div>
        <div className='Section-9 bg-gradient-to-r py-20 from-[#185CC2D9]/95 via-[#0A2973D9] to-[#0E183700]/90 min-h-screen ' id='contact'>
          <div className="container mx-auto ">
            <h1 className='tracking-widest text-center text-white text-2xl lg:text-4xl uppercase font-medium tracking-wider'>GET   IN   TOUCH
            </h1>
            <div className='w-[90%] md:w-[50%] mx-auto'>
              <div><img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img></div>
              <p className='border-b border-white/50'></p>
            </div>
            <div className='flex justify-center mt-3'>
              <p className='text-center text-[#E8BA3C] font-inter text-xl w-[90%] md:w-[100%]'>We'd love to hear from you. Let's discuss how we can help you achieve your goals.</p>
            </div>

            <div className="mt-5 w-[90%] lg:w-[75%] md:w-[85%] mx-auto bg-[#0D2262] border border-black md:p-8 p-4">
            <h4 className="py-4 text-white text-center md:text-2xl text-xl font-roboto">CONTACT FORM</h4>
            <form onSubmit={handleSubmit}>
              <div className="md:w-[85%] lg:w-[65%] mx-auto">
                <input
                  className="my-2 placeholder:text-white-400 block bg-transparent w-full border border-white-300 rounded-md py-3 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm text-white"
                  placeholder="Name"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <input
                  className="my-2 placeholder:text-white-400 block bg-transparent w-full border border-white-300 rounded-md py-3 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm text-white"
                  placeholder="Email"
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <input
                  className="my-2 placeholder:text-white-400 block bg-transparent w-full border border-white-300 rounded-md py-3 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm text-white"
                  placeholder="Subject"
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                />
                <textarea
                  className="my-2 placeholder:text-white-400 h-[100px] block bg-transparent w-full border border-white-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm text-white"
                  placeholder="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="flex items-center justify-center w-full lg:my-4 my-2">
                <button
                  type="submit"
                  className="relative overflow-hidden px-7 md:px-20 py-3 text-white bg-gradient-to-br from-blue-600 via-blue-800 to-[#E8BA3C80] font-bold uppercase tracking-wider border border-blue-400 shadow-md"
                >
                  SUBMIT
                  <span className="absolute inset-x-0 bottom-0 h-1 bg-yellow-500"></span>
                </button>
              </div>
            </form>
          </div>
          </div>
        </div>
        <div className='Section-10 bg-[#0f1938] text-center bg-  z-20  py-20 w-[100%] grid content-center'>
          <h1 className='uppercase tracking-widest text-4xl 2xl:text-5xl font-medium'>Our Leaders </h1>
          <div className='w-[32%] mx-auto'>
            <img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img>
            <p className='border-b border-white/50'></p>
          </div>
          <p className='w-[90%] mx-auto mt-5 text-lg 2xl:text-2xl'>At FABC, we're proud to collaborate with a diverse network of global Consortium partners who share our commitment to innovation and excellence. These strategic alliances are the cornerstone of our success, enabling us to deliver cutting-edge solutions and drive positive change across industries.</p>
          <div className='flex flex-col gap-40 lg:gap-0 md:flex-row items-center lg:justify-around w-[90%] mx-auto mt-20 lg:mt-16'>
            <div className='md:w-[25%] '>
              <img src='/assets/Testimonials/Group 2.png'></img>
              <div className='flex flex-col gap-3 py-2'>
                <p className='font-bold uppercase text-2xl'>Sakthi Visakan <br />Rajaguru</p>
                <img src='/assets/Gradient-Line-768x1.png.png'></img>
                <p className='text-xl mt-2 text-[#E8BA3C] font-medium uppercase font-inter'>Chief Executive Officer</p>
              </div>
            </div>
            <div className='md:w-[25%] '>
              <img src='/assets/Testimonials/Group 1 (28).png'></img>
              <div className='flex flex-col gap-3 py-2'>
                <p className='font-bold uppercase text-2xl'>Annamalai <br /> Palaniappan</p>
                <img src='/assets/Gradient-Line-768x1.png.png'></img>
                <p className='text-xl mt-2 text-[#E8BA3C] font-medium uppercase font-inter'>Chief Operating Officer</p>
              </div>
            </div>
            <div className='md:w-[25%] '>
              <img src='/assets/Testimonials/Group 2 (1).png'></img>
              <div className='flex flex-col gap-3 py-2'>
                <p className='font-bold uppercase text-2xl'>Anup <br />Kumar</p>
                <img src='/assets/Gradient-Line-768x1.png.png'></img>
                <p className='text-xl mt-2 text-[#E8BA3C] font-medium uppercase font-inter'>Chief Technology Officer</p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Footer />
    </div>
  )
}
