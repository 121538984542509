import React from 'react'

export default function Section8() {
  return (
    <div className='bg-[#0D327E] text-white min-h-screen py-10'>
      <div className=' '>
        <h1 className='text-3xl lg:text-4xl text-center font-sego mb-3'>Blogs</h1>
      </div>
      <div className='flex flex-col gap-10'>
        <div className='w-[90%] mx-auto flex flex-col lg:flex-row justify-center gap-8'>
          <div className='lg:w-[25%] bg-[#0D327E] border-b border-white py-5 grid content-around' data-aos='flip-up' data-aos-duration='1500'>
            <div className=''>
              <img src='/assets/Others/Sec81.svg'></img>
            </div>
            <div className='py-5'>
              <h1 className='font-inter text-white'>From Concept to Execution: Developing a Successful AI-Driven Flash Loan Platform.</h1>
            </div>
            <a href='https://medium.com/@FABCLLC/from-concept-to-execution-developing-a-successful-ai-driven-flash-loan-platform-fdeb88fad5b5'
              className='flex justify-between items-center'>
              <div>
                <p className='text-sm font-sego'>Click here</p>
              </div>
              <div>
                <button><img src='/assets/Icon/Section8-icon.svg'></img></button>
              </div>
            </a>
          </div>
          <div className='lg:w-[25%] bg-[#0D327E] border-b border-white py-5 grid content-around' data-aos='flip-up' data-aos-duration='2000'>
            <div>
              <img src='/assets/Others/Sec82.svg'></img>
            </div>
            <div className='py-5'>
              <h1 className='font-inter text-white'>From Struggle to Success: How D5ART Solves Freshers’ Placement Challenges with NFT CVs.</h1>
            </div>
            <a href='https://medium.com/@FABCLLC/from-struggle-to-success-how-d5art-solves-freshers-placement-challenges-with-nft-cvs-52363e0d415f' className='flex justify-between items-center'>
              <div>
                <p className='text-sm font-sego'>Click here</p>
              </div>
              <div>
                <button><img src='/assets/Icon/Section8-icon.svg'></img></button>
              </div>
            </a>
          </div>
          <div className='lg:w-[25%] bg-[#0D327E] border-b border-white py-5 grid content-around' data-aos='flip-up' data-aos-duration='2500'>
            <div className=''>
              <img src='/assets/Others/Sec83.svg'></img>
            </div>
            <div className='py-5'>
              <h1 className='font-inter text-white'>Decoding You: What Secrets Does Your DNA Hold?</h1>
            </div>
            <a href='https://medium.com/@FABCLLC/decoding-you-what-secrets-does-your-dna-hold-c8469da0f3be'
              className='flex justify-between items-center'>
              <div>
                <p className='text-sm font-sego'>Click here</p>
              </div>
              <div>
                <button><img src='/assets/Icon/Section8-icon.svg'></img></button>
              </div>
            </a>
          </div>
        </div>
        <div className='w-[90%] mx-auto flex flex-col lg:flex-row justify-center gap-8'>
          <div className='lg:w-[25%] bg-[#0D327E] border-b border-white py-5 grid content-around' data-aos='flip-up' data-aos-duration='1500'>
            <div className=''>
              <img src='/assets/Others/Sec84.svg'></img>
            </div>
            <div className='py-5'>
              <h1 className='font-inter text-white'>The Winning Goal: Blockchain and NFTs Revolutionizing Soccer Talent Management.</h1>
            </div>
            <a href='https://medium.com/@FABCLLC/the-winning-goal-blockchain-and-nfts-revolutionizing-soccer-talent-management-74cc9e429312'
              className='flex justify-between items-center'>
              <div>
                <p className='text-sm font-sego'>Click here</p>
              </div>
              <div>
                <button><img src='/assets/Icon/Section8-icon.svg'></img></button>
              </div>
            </a>
          </div>
          <div className='lg:w-[25%] bg-[#0D327E] border-b border-white py-5 grid content-around' data-aos='flip-up' data-aos-duration='2000'>
            <div className=''>
              <img src='/assets/Others/Sec85.svg'></img>
            </div>
            <div className='py-5'>
              <h1 className='font-inter text-white'>Recruitment 3.0 Evolution: A Journey from Tradition to Tokenization.</h1>
            </div>
            <a href='https://medium.com/@FABCLLC/recruitment-3-0-evolution-a-journey-from-tradition-to-tokenization-cfbbabdabdc8'
              className='flex justify-between items-center'>
              <div>
                <p className='text-sm font-sego'>Click here</p>
              </div>
              <div>
                <button><img src='/assets/Icon/Section8-icon.svg'></img></button>
              </div>
            </a>
          </div>
          <div className='lg:w-[25%] bg-[#0D327E] border-b border-white py-5 grid content-around' data-aos='flip-up' data-aos-duration='2500'>
            <div className=''>
              <img src='/assets/Others/Sec86.svg'></img>
            </div>
            <div className='py-5'>
              <h1 className='font-inter text-white'>Beans to Bucks: Maximizing Returns with Coffee Tracking and Tracing Platform.</h1>
            </div>
            <a href='https://medium.com/@FABCLLC/beans-to-bucks-maximizing-returns-with-coffee-tracking-and-tracing-platforms-2d9e093df88c'
              className='flex justify-between items-center'>
              <div>
                <p className='text-sm font-sego'>Click here</p>
              </div>
              <div>
                <button><img src='/assets/Icon/Section8-icon.svg'></img></button>
              </div>
            </a>

          </div>
        </div>
      </div>
    </div>
  )
}
