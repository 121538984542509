import React from 'react'
import "../Style/Landing.css";

export default function Section9() {
  return (
    <div className='bg-gradient-to-b from-[#FFFFF7] to-[#0D327E] min-h-screen py-10'>
      <div>
        <h1 className='text-3xl lg:text-4xl text-center font-sego text-black'>Empowering Innovation & Global Growth</h1>
        <p className='mt-7 text-center text-black w-[90%] md:w-[60%] mx-auto tracking-widest'>FABC Labs bridges the gap between ideas and market success, offering tokenization, incubation, and launch support for innovators </p>
      </div>
      <div className='lg:block hidden'>
        <div className='relative flex justify-center items-center mt-20 '>

          <div className=' DIV-1 p-10 border border-white/20 w-[60%] flex justify-center' style={{ borderRadius: "150px" }}>
            <div className='DIV-2 p-10 border border-white/40 w-[90%] flex justify-center' style={{ borderRadius: "140px" }}>
              <div className='DIV-3 p-10 border border-white/60 w-[90%] flex justify-center' style={{ borderRadius: "120px" }}>
                <div className='DIV-4 p-10 border border-white/80 w-[90%] flex justify-center' style={{ borderRadius: "80px" }}>
                  <button className='flex justify-center items-center px-5 py-2 bg-[#0D327E] text-white rounded-full white-shadow-box'><img src='/assets/Icon/Section9-icon.svg'></img>&nbsp;Get started for free</button>
                </div>
              </div>
            </div>
          </div>

          <div className=''>
            <img className='absolute top-0 left-[33%] w-1/12' src='/assets/Others/Sec9-1.svg'></img>
            <img className='absolute top-10 left-[48%] w-[6%]' src='/assets/Others/Sec9-2.svg' ></img>
            <img className='absolute top-1 right-[33%] w-[5%]' src='/assets/Others/Sec9-3.svg' ></img>
            <button className='absolute top-[32%] left-[23%] flex justify-center items-center font-sego bg-black rounded-full py-1 px-7 w-[10%] text-white'><img className='w-[40%]' src='/assets/Others/Sec9-4.svg'></img>&nbsp;Innovators</button>
            <button className='absolute top-[42%] right-[23%] flex justify-center items-center font-sego bg-black rounded-full py-1 px-7 w-[10%] text-white'><img className='w-[40%]' src='/assets/Others/Sec9-4.svg'></img>&nbsp;Investors</button>
            <img className='absolute bottom-0 left-[28%] w-1/12' src='/assets/Others/Sec9-5.svg'></img>
            <img className='absolute bottom-10 left-[40%] w-[6%]' src='/assets/Others/Sec9-6.svg' ></img>
            <button className='absolute bottom-0 right-[40%] flex justify-center items-center font-sego bg-black rounded-full py-1 px-7 w-[10%] text-white'><img className='w-[40%]' src='/assets/Others/Sec9-4.svg'></img>&nbsp;Enterprises</button>
            <img className='absolute bottom-1 right-[23%] w-[7%]' src='/assets/Others/Sec9-7.svg' ></img>

          </div>


        </div>
      </div>
      <div className='lg:hidden block'>
        <div className='flex justify-center'>
          <a><img src='/assets/Others/Group 1597883578.svg'></img></a>
        </div>
      </div>
    </div>
  )
}
