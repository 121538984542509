import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Footer() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className='bg-[#fff] pt-16  text-black'>
      <div className='flex justify-center'>
        <div className='w-[94%] border border-white'></div>
      </div>

      <div className='flex flex-col lg:flex-row justify-between'>
        <div className='lg:w-[30%] py-2 lg:py-4 '>
          <p className='text-left font-bold font-poppins text-xl px-10'>Contact Us</p>
          <div className='flex flex-col gap-1 lg:gap-4 py-2 lg:py-6'>
            <div className='flex justify-start px-10 items-center gap-4'>
              <i class="fa-solid fa-phone" style={{ color: "#000" }}></i>
              <p className='000 font-roboto'> +971 54 789 4736  , +91 95662 99429</p>
            </div>
            <div className='flex justify-start px-10 items-center gap-4'>
              <i class="fa-solid fa-envelope" style={{ color: "#000" }}></i>
              <p className='000 font-inter'>support@fabclabs.com</p>
            </div>
            <div className='flex justify-start px-10 gap-4'>
              <i class="fa-solid fa-location-dot" style={{ color: "#000" }}></i>
              <p className='000 font-inter text-left'>Plot 301-5, 32D Street Al Fattan Downtown, Al Satwa, Dubai, UAE PO Box No: 142507
                <br className='hidden lg:block'></br><br className='hidden lg:block'></br> </p>
            </div>
          </div>
        </div>
        <div className='lg:w-[30%] py-2 lg:py-4'>
          <p className='text-left px-10 lg:px-0 lg:text-center font-bold font-poppins text-xl'>Our Services</p>
          <div className='flex lg:justify-center px-10 lg:px-0'>
            <div className='flex flex-col gap-1 lg:gap-4 py-2 lg:py-6 '>
              <NavLink onClick={() => scrollToSection('about')} className='000 font-poppins text-left '>About Us</NavLink>
              <NavLink onClick={() => scrollToSection('products')} href='/Products' className='text-left 000 font-poppins'>Products</NavLink>
              <NavLink onClick={() => scrollToSection('services')} href="/Service" className='text-left 000 font-poppins'>Services</NavLink>
              <NavLink onClick={() => scrollToSection('innovation')} href="/Contactus" className='text-left 000 font-poppins'>Innovation Hub</NavLink>
            </div>
          </div>

        </div>
        <div className='lg:w-[30%] py-2 lg:py-4'>
          <p className='text-left px-10 lg:px-0 lg:text-center font-bold font-poppins text-xl'>Quick Link</p>
          <div className='flex lg:justify-center px-10 lg:px-0'>
            <div className='flex flex-col gap-1 lg:gap-4 py-2 lg:py-6'>
              <a href="https://medium.com/@fabclabs" target='_blank' className='text-left 000 font-poppins'>Blog</a>
              <NavLink onClick={() => scrollToSection('contact')} href="/Contactus" className='text-left 000 font-poppins'>Get In Touch</NavLink>
            </div>
          </div>
          <div className='mt-10'>

            <div className='flex px-10 lg:justify-end justify-start gap-8 lg:px-20 '>
              <a href='https://www.linkedin.com/company/fabc-labs' target='_blank'><i class="fa-brands fa-linkedin-in" style={{ color: "#000" }}></i></a>
              <a href='https://x.com/FabcL8861?tpclid=facebook.PAZXh0bgNhZW0CMTEAAabKQJ3KKR5y4HZwR-9lKK3S34BkjKuvLKr1mUDVl2gOScOaHCj5zTGKwFQ_aem_GUGZ9wrf0NZgqW2GviE5UQ' target='_blank'><i class="fa-brands fa-x-twitter" style={{ color: "#000" }}></i></a>
              <a href='https://www.facebook.com/fabclabsllc/?tpclid=facebook.PAZXh0bgNhZW0CMTEAAabKQJ3KKR5y4HZwR-9lKK3S34BkjKuvLKr1mUDVl2gOScOaHCj5zTGKwFQ_aem_GUGZ9wrf0NZgqW2GviE5UQ' target='_blank'><i class="fa-brands fa-facebook" style={{ color: "#000" }}></i></a>
              <a href='https://www.instagram.com/fabc_labs/?tpclid=facebook.PAZXh0bgNhZW0CMTEAAabKQJ3KKR5y4HZwR-9lKK3S34BkjKuvLKr1mUDVl2gOScOaHCj5zTGKwFQ_aem_GUGZ9wrf0NZgqW2GviE5UQ' target='_blank'><i class="fa-brands fa-instagram" style={{ color: "#000" }}></i></a>
              <a href='https://medium.com/@fabclabs' target='_blank'><i class="fa-brands fa-medium" style={{ color: "#000" }}></i></a>
              <a href='https://www.reddit.com/user/FABCLABSLLC/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button' target='_blank'><i class="fa-brands fa-reddit" style={{ color: "#000" }}></i></a>


            </div>
          </div>
        </div>

      </div>
      <div className='lg:block hidden'>
        <p className=' font-marc text-7xl text-center' style={{ letterSpacing: "60px" }}>FABC LABS</p>
      </div>
      <div className='block lg:hidden'>
        <p className=' font-marc text-4xl text-center' style={{ letterSpacing: "20px" }}>FABC LABS</p>
      </div>
      {/* 
        <div className='w-[95%] flex flex-col gap-3'>
       <div className='w-[95%] flex flex-col gap-2 lg:flex-row justify-between items-center'>
          <div className='flex  lg:gap-10 gap-3'>
           <a className='000 font-inter'>About Us</a>
           <a href='/Products' className='000 font-inter'>Products</a>
           <a href="/Service" className='000 font-inter'>Services</a>
           <a href="/Contactus" className='000 font-inter'>Contact Us</a>
           <a href="/" className='000 font-inter'>Blog</a>
          </div>
          <div className='flex gap-8'>
          <a href='https://www.linkedin.com/company/fabc-labs' target='_blank'><i class="fa-brands fa-linkedin-in" style={{color: "#000"}}></i></a>
          <a href='https://x.com/FabcL8861?tpclid=facebook.PAZXh0bgNhZW0CMTEAAabKQJ3KKR5y4HZwR-9lKK3S34BkjKuvLKr1mUDVl2gOScOaHCj5zTGKwFQ_aem_GUGZ9wrf0NZgqW2GviE5UQ' target='_blank'><i class="fa-brands fa-x-twitter" style={{color: "#000"}}></i></a>
          <a href='https://www.facebook.com/fabclabsllc/?tpclid=facebook.PAZXh0bgNhZW0CMTEAAabKQJ3KKR5y4HZwR-9lKK3S34BkjKuvLKr1mUDVl2gOScOaHCj5zTGKwFQ_aem_GUGZ9wrf0NZgqW2GviE5UQ' target='_blank'><i class="fa-brands fa-facebook" style={{color: "#000"}}></i></a>
          <a href='https://www.instagram.com/fabc_labs/?tpclid=facebook.PAZXh0bgNhZW0CMTEAAabKQJ3KKR5y4HZwR-9lKK3S34BkjKuvLKr1mUDVl2gOScOaHCj5zTGKwFQ_aem_GUGZ9wrf0NZgqW2GviE5UQ' target='_blank'><i class="fa-brands fa-instagram" style={{color: "#000"}}></i></a>
          </div>
          </div>
        <div className='flex flex-col gap-5 lg:flex-row justify-start lg:gap-20'>
         <div className='flex justify-center items-center gap-4'>
         <i class="fa-solid fa-location-dot" style={{color: "#000"}}></i>
         <p className='000 font-inter'>First Academy of Blockchain Council No: 51, Ground Floor, Tower A Tek Meadows, OMR Sholinganallur, Chennai</p>
         </div>
         <div className='flex justify-start lg:justify-center items-center gap-4'>
         <i class="fa-solid fa-envelope" style={{color: "#000"}}></i>
         <p className='000 font-inter'>support@fabc.global</p>
         </div>
         <div className='flex justify-start lg:justify-center items-center gap-4'>
         <i class="fa-solid fa-phone" style={{color: "#000"}}></i>
         <p className='000 font-inter'>+919566299429</p>
         </div>
        </div>
       </div>
       */}
    </div>
  )
}
