import React, { useState } from 'react'
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

export default function Section7() {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://fabclabs.global/api/form', formData);
      console.log(response);
      toast.success('Form submitted successfully!');
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit form. Please try again.');
    }
  };
  return (
    <div>
      <Toaster position="top-right"/>
      <div className=' bg-white bg-sec1BG bg-cover py-20  min-h-screen ' >
        <div className="container mx-auto ">
          <h1 className='text-3xl lg:text-4xl text-center font-sego'>Reach Out, We Got You
          </h1>

          <div className='flex justify-center mt-3'>
            <p className='text-center text-[#000]  font-inter text-xl w-[90%] md:w-[100%] mt-5' style={{ lineHeight: "32.7px" }}>We'd love to hear from you. Let's discuss how we can help <br></br>you achieve your goals.</p>
          </div>

          <div className="mt-5 w-[90%] lg:w-[75%] md:w-[85%] mx-auto bg-[#0D327E] border border-black md:p-8 p-4" data-aos='fade-up' data-aos-duration='1500'>
            <h4 className="py-4 text-white text-center md:text-2xl text-xl font-roboto">CONTACT FORM</h4>
            <form onSubmit={handleSubmit}>
              <div className="md:w-[85%] lg:w-[65%] mx-auto">
                <input
                  className="my-2 placeholder:text-white-400 block bg-transparent w-full border border-white-300 rounded-md py-3 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm text-white"
                  placeholder="Name"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <input
                  className="my-2 placeholder:text-white-400 block bg-transparent w-full border border-white-300 rounded-md py-3 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm text-white"
                  placeholder="Email"
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <input
                  className="my-2 placeholder:text-white-400 block bg-transparent w-full border border-white-300 rounded-md py-3 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm text-white"
                  placeholder="Subject"
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                />
                <textarea
                  className="my-2 placeholder:text-white-400 h-[100px] block bg-transparent w-full border border-white-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm text-white"
                  placeholder="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="flex items-center justify-center w-full lg:my-4 my-2">
                <button
                  type="submit"
                  className="relative overflow-hidden px-7 md:px-20 py-3 bg-white text-[#0D327E]  font-bold uppercase tracking-wider border border-blue-400 shadow-md"
                >
                  SUBMIT

                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
