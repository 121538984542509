import React, { useState } from 'react';
import { motion } from "framer-motion";

export default function Section4() {

    const [visibleSteps, setVisibleSteps] = useState(1);
    const steps = [
        {
            id: 1,
            image: "sec4_1.svg",
            label: "Wallet Connectivity",
        },
        {
            id: 2,
            image: "sec4_2.svg",
            label: "Crypto Payment Gateways",
        },
        {
            id: 3,
            image: "sec4_3.svg",
            label: "Air Drop BOT",
        },
        {
            id: 4,
            image: "sec4_4.svg",
            label: "Trading BOT",
        },
        {
            id: 5,
            image: "sec4_7.svg",
            label: "NFT Marketplaces",
        },
        {
            id: 6,
            image: "sec4_6.svg",
            label: "Smart Contract Development",
        },
        {
            id: 7,
            image: "sec4_5.svg",
            label: "Telegram Application",
        },
        {
            id: 8,
            image: "sec4_8.svg",
            label: "Multi Level Marketing Platform",
        },
        {
            id: 9,
            image: "sec4_9.svg",
            label: "Token Sale Dashboard",
        },
        {
            id: 10,
            image: "sec4_10.svg",
            label: "NFT Minting Engine",
        },
    ];

    return (
        <div className='bg-[#0D327E] min-h-screen grid content-center relative overflow-hidden py-10'>
            <div className='w-[90%] mx-auto relative' style={{ zIndex: 1 }}>
                <h1 className='text-3xl lg:text-4xl text-center text-white'> White-label Solutions</h1>
                <p className='mt-7 2xl:mt-10 text-center text-white/75 w-[90%] md:w-[60%] mx-auto tracking-widest'>We provide a wide range of ready-to-deploy white-label solutions to help businesses scale rapidly and efficiently.</p>
                <div className='hidden lg:block'>
                    <div className=" flex flex-wrap items-center justify-center text-lg font-semibold p-6 pt-0 mt-10 2xl:mt-14">
                        {steps.slice(0, visibleSteps).map((step, index) => (
                            <motion.div
                                key={step.id}
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.5 }}
                                onAnimationComplete={() => {
                                    if (index === visibleSteps - 1 && visibleSteps < steps.length) {
                                        setTimeout(() => setVisibleSteps(visibleSteps + 1), 500);
                                    }
                                }}
                                className="flex items-center"
                            >
                                <div className="flex items-center border-x-4 border-y-2 border-[#008CFF] bg-white px-1 rounded-lg mb-10">
                                    <img src={`/assets/Icon/${step.image}`} className="w-12 border-r border-[#0033AD7A] pr-1" alt={step.label} />
                                    <p className="text-sm 2xl:text-lg text-center px-2 py-3">{step.label}</p>
                                </div>
                                {index < steps.length - 1 && (
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.5, delay: index * 0.5 }}
                                        className="flex items-center"
                                    >
                                        <motion.div
                                            className="w-14 border-t border-dashed border-white mb-10"
                                            initial={{ width: 0 }}
                                            animate={{ width: "3.5rem" }}
                                            transition={{ duration: 0.5, delay: index * 0.5 }}
                                        />
                                        <motion.div
                                            className="w-2 h-2 bg-white drop-shadow-lg mb-10"
                                            initial={{ rotate: 45, boxShadow: 'rgba(0,0,0,0.5)' }}
                                            animate={{
                                                boxShadow: [
                                                    "0 0 8px rgba(30, 239, 50, 0.5)",
                                                    "0 0 12px rgba(30, 239, 50, 0.8)",
                                                    "0 0 8px rgba(30, 239, 50, 0.5)",
                                                ],
                                            }}
                                            transition={{ duration: 1.5, repeat: Infinity }}
                                        />
                                    </motion.div>
                                )}
                            </motion.div>
                        ))}
                    </div>
                </div>
                {/*<img src='/assets/Others/flow-chart.svg' className='mt-10 lg:block hidden mx-auto'></img>*/}
                <div className='block lg:hidden mt-10  space-y-4 text-[8px] md:text-base'>
                    <div className='flex justify-center items-center' data-aos='fade-left' data-aos-duration='2000'>
                        <div className='flex w-[45%] items-center border-x-4 border-y-2 border-[#008CFF] bg-white px-1 rounded-lg'>
                            <img src='/assets/Icon/sec4_1.svg' className='border-r border-[#0033AD7A] pr-1'></img>
                            <p className='px-1 py-2'>Token Development</p>
                        </div>
                        <div className='w-5 border-t border-dashed'><div className='w-1 h-1 bg-white rotate-45 translate-x-4 -translate-y-[2px]'></div></div>
                        <div className='flex w-[45%] items-center border-x-4 border-y-2 border-[#008CFF] bg-white px-1 rounded-lg'>
                            <img src='/assets/Icon/sec4_2.svg' className='border-r border-[#0033AD7A] pr-1'></img>
                            <p className='px-1 py-2'>Crypto Payment Gateways</p>
                        </div>
                    </div>
                    <div className='flex justify-center items-center' data-aos='fade-right' data-aos-duration='2000'>
                        <div className='flex w-[45%] items-center border-x-4 border-y-2 border-[#008CFF] bg-white px-1 rounded-lg'>
                            <img src='/assets/Icon/sec4_3.svg' className='border-r border-[#0033AD7A] pr-1'></img>
                            <p className='px-1 py-2'>Air Drop BOT</p>
                        </div>
                        <div className='w-5 border-t border-dashed'><div className='w-1 h-1 bg-white rotate-45 translate-x-4 -translate-y-[2px]'></div></div>
                        <div className='flex w-[45%] items-center border-x-4 border-y-2 border-[#008CFF] bg-white px-1 rounded-lg'>
                            <img src='/assets/Icon/sec4_4.svg' className='border-r border-[#0033AD7A] pr-1'></img>
                            <p className='px-1 py-2'>Trading BOT</p>
                        </div>
                    </div>
                    <div className='flex justify-center items-center' data-aos='fade-left' data-aos-duration='2000'>
                        <div className='flex w-[45%] items-center border-x-4 border-y-2 border-[#008CFF] bg-white px-1 rounded-lg'>
                            <img src='/assets/Icon/sec4_5.svg' className='border-r border-[#0033AD7A] pr-1'></img>
                            <p className='px-1 py-2'>Telegram Application</p>
                        </div>
                        <div className='w-5 border-t border-dashed'><div className='w-1 h-1 bg-white rotate-45 translate-x-4 -translate-y-[2px]'></div></div>
                        <div className='flex w-[45%] items-center border-x-4 border-y-2 border-[#008CFF] bg-white px-1 rounded-lg'>
                            <img src='/assets/Icon/sec4_6.svg' className='border-r border-[#0033AD7A] pr-1'></img>
                            <p className='px-1 py-2'>Smart Contract Development</p>
                        </div>
                    </div>
                    <div className='flex justify-center items-center' data-aos='fade-right' data-aos-duration='2000'>
                        <div className='flex w-[45%] items-center border-x-4 border-y-2 border-[#008CFF] bg-white px-1 rounded-lg'>
                            <img src='/assets/Icon/sec4_7.svg' className='border-r border-[#0033AD7A] pr-1'></img>
                            <p className='px-1 py-2'>NFT Marketplaces</p>
                        </div>
                        <div className='w-5 border-t border-dashed'><div className='w-1 h-1 bg-white rotate-45 translate-x-4 -translate-y-[2px]'></div></div>
                        <div className='flex w-[45%] items-center border-x-4 border-y-2 border-[#008CFF] bg-white px-1 rounded-lg'>
                            <img src='/assets/Icon/sec4_8.svg' className='border-r border-[#0033AD7A] pr-1'></img>
                            <p className='px-1 py-2'>Multi Level Marketing Platform</p>
                        </div>
                    </div>
                    <div className='flex justify-center items-center' data-aos='fade-left' data-aos-duration='2000'>
                        <div className='flex w-[45%] items-center border-x-4 border-y-2 border-[#008CFF] bg-white px-1 rounded-lg'>
                            <img src='/assets/Icon/sec4_9.svg' className='border-r border-[#0033AD7A] pr-1'></img>
                            <p className='px-1 py-2'>Token Sale Dashboard</p>
                        </div>
                        <div className='w-5 border-t border-dashed'><div className='w-1 h-1 bg-white rotate-45 translate-x-4 -translate-y-[2px]'></div></div>
                        <div className='flex w-[45%] items-center border-x-4 border-y-2 border-[#008CFF] bg-white px-1 rounded-lg'>
                            <img src='/assets/Icon/sec4_10.svg' className='border-r border-[#0033AD7A] pr-1'></img>
                            <p className='px-1 py-2'>NFT Minting Engine</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='absolute -bottom-28 -right-28 rotate-45'>
                <div className="border-wave-2">
                    <div className="border-wave-2">
                        <div className="border-wave-2">
                            <div className="border-wave-2">
                                <div className="border-wave-2">
                                    <div className="border-wave-2">
                                        <div className="border-wave-2">
                                            <div className="border-wave-2">
                                                <div className="hidden md:block border-wave-2">
                                                    <div className="hidden md:block border-wave-2">
                                                        <div className="hidden md:block border-wave-2">
                                                            <div className="hidden md:block border-wave-2">
                                                                <div className="hidden md:block border-wave-2">
                                                                    <div className="hidden md:block border-wave-2">
                                                                        <div className="hidden md:block border-wave-2">
                                                                            <div className="hidden md:block border-wave-2">
                                                                                <div className="w-5 h-5 border rounded-full border-white/20"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute top-10 -left-14 md:left-10">
                <div className="border-wave">
                    <div className="border-wave">
                        <div className="border-wave">
                            <div className="border-wave">
                                <div className="border-wave">
                                    <div className="border-wave">
                                        <div className="border-wave">
                                            <div className="border-wave">
                                                <div className="border-wave">
                                                    <div className="border-wave">
                                                        <div className="hidden md:block border-wave">
                                                            <div className="hidden md:block border-wave">
                                                                <div className="hidden md:block border-wave">
                                                                    <div className="hidden md:block border-wave">
                                                                        <div className="hidden md:block border-wave">
                                                                            <div className="hidden md:block border-wave">
                                                                                <div className="hidden md:block border-wave">
                                                                                    <div className="hidden md:block border-wave">
                                                                                        <div className="w-5 h-5 border rounded-full border-white/20"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='absolute top-[70%] md:top-[30%] -right-12 md:-right-[4%]'>
                <div className="border-wave">
                    <div className="border-wave">
                        <div className=" border-wave">
                            <div className=" border-wave">
                                <div className=" border-wave">
                                    <div className="border-wave">
                                        <div className="hidden md:block border-wave">
                                            <div className="hidden md:block border-wave">
                                                <div className="hidden md:block border-wave">
                                                    <div className="hidden md:block border-wave">
                                                        <div className="w-5 h-5 border rounded-full border-white/20"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
