import React, { useEffect, useRef, useState } from 'react';
import 'animate.css';
import { NavLink } from 'react-router-dom';
import '../Styles/App.css';

export default function Header() {
  const [isDropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const handleDropdown = () => {
    setDropdown(!isDropdown);
  };


  const handleClickOutside = (event) => {
    if (isDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdown]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <div className=' hidden lg:block z-50'>
        <div className="bg-gradient-to-r from-[#836b2a] via-[#f1b206] to-[#836b2a]  pb-[1px] z-50">
          <div className="bg-gradient-to-r from-[#050512] via-[#0f103e] to-[#050512] flex items-center 2xl:py-6 xl:py-5 lg:py-4 px-5  z-50">
            <div className='w-[30%] px-5'>
              <img src='/assets/Logo/FABCLABS.png' className='mr-auto w-[25%]'></img>
              {/*
       <p className="font-inter text-white font-bold text-xl">FABCLABS</p>*/}
            </div>

            <div className='header-content w-[70%] flex items-center justify-end gap-8 px-20 text-xl'>
              <NavLink onClick={() => scrollToSection('about')} to="/" className="cursor-pointer font-inter text-white  z-50">About Us</NavLink>
              <NavLink onClick={() => scrollToSection('products')} to="/" className="cursor-pointer font-inter text-white  z-50">Products</NavLink>
              <NavLink onClick={() => scrollToSection('services')} to="/" className="cursor-pointer font-inter text-white  z-50">Services</NavLink>
              <NavLink onClick={() => scrollToSection('innovation')} to="/" className="cursor-pointer font-inter text-white  z-50">Innovation Hub</NavLink>
              <NavLink onClick={() => scrollToSection('contact')} to="/" className="cursor-pointer font-inter text-white  z-50">Contact Us</NavLink>
            </div>
          </div>
        </div>

      </div>
      <div className="lg:hidden block bg-gradient-to-r from-[#836b2a] via-[#f1b206] to-[#836b2a]  pb-[5px]">
        <div className=' bg-gradient-to-r from-[#113575] to-[#10306a] flex items-center justify-between px-5 py-4'>
          <div>
            {/*
      <p className="font-inter text-white font-bold text-xl">FABCLABS</p>*/}
            <img src='/assets/Logo/FABCLABS.png' className='mr-auto w-[25%]'></img>

          </div>
          <div>
            <a
              className='text-white font-bold text-2xl'
              onClick={handleDropdown}
              ref={buttonRef}
            >
              {isDropdown ? (
                <i className="fa-solid fa-xmark"></i>
              ) : (
                <i className="fa-solid fa-bars"></i>
              )}
            </a>      </div>
          {isDropdown && (
            <div

              className="dropdown-content z-50 w-[12rem] absolute right-5 top-[4rem] mt-2 top-20  animate__animated animate__fadeInUp "
              data-aos="fade-up"
              ref={dropdownRef}
            >
              <div className="flex flex-col gap-3 border-2  backdrop-blur-md text-center  rounded-lg p-3 bg-gradient-to-r from-[#113575] to-[#10306a]" >
                <NavLink onClick={() => scrollToSection('about')} to="" className="cursor-pointer font-inter text-white text-xl z-50">About Us</NavLink>
                <NavLink onClick={() => scrollToSection('products')} to="" className="cursor-pointer font-inter text-white text-xl z-50">Products</NavLink>
                <NavLink onClick={() => scrollToSection('services')} to="" className="cursor-pointer font-inter text-white text-xl z-50">Services</NavLink>
                <NavLink onClick={() => scrollToSection('innovation')} to="" className="cursor-pointer font-inter text-white text-xl z-50">Innovation Hub</NavLink>
                <NavLink onClick={() => scrollToSection('contact')} to="" className="cursor-pointer font-inter text-white text-xl z-50">Contact Us</NavLink>



              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
