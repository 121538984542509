import React from 'react'
import '../Style/Landing.css';
import Marquee from "react-fast-marquee";



export default function Section1() {
    return (
        <div className='bg-cover pt-10 bg-sec1BG'>
            <div className='w-[90%] mx-auto text-center relative'>
                <div className='hidden md:block'>
                    <div className='flex justify-center'>
                        <div className='relative flex items-center justify-center'>
                            <div className='border-icon-wave-1'>
                            </div>
                            <div className='border-icon-wave-2'>
                            </div>
                            <div className='border-icon-wave-3'>
                            </div>
                            <img src='/assets/Icon/sec_icon_1.svg' className='w-12'></img>
                        </div>
                    </div>
                </div>

                <h1 className='Lets-content text-4xl md:text-5xl mt-5'>Let’s Innovate Together</h1>
                <p className='Empower-content mt-10 tracking-wide md:tracking-wider text-lg md:text-2xl'>Empower your assets with digital freedom transform,<br className=' hidden lg:block' /> tokenize, and lead the future with FABC LABS. </p>
                <button className='mt-10 mb-10 uppercase text-white bg-[#0D327E] px-12 py-3 font-medium shadow-md shadow-black/30'>Build Your Product</button>
                <h2 className='spread-fabc my-7 md:my-0 text-[#393939] text-[35px] md:text-[70px] lg:text-[90px] font-marc tracking-[1rem] md:tracking-[2rem] lg:tracking-[4rem] '>FABC LABS</h2>
                <div>
                    <div className='absolute top-16 left-10 hidden lg:block'>
                        <div className='relative flex items-center justify-center'>
                            <div className='border-icon-wave-1'>
                            </div>
                            <div className='border-icon-wave-2'>
                            </div>
                            <div className='border-icon-wave-3'>
                            </div>
                            <img src='/assets/Icon/sec_icon_2.svg' className='w-14 mx-auto'></img>
                        </div>
                    </div>
                    <div className='absolute top-20 right-10 hidden lg:block'>
                        <div className='relative flex items-center justify-center'>
                            <div className='border-icon-wave-1'>
                            </div>
                            <div className='border-icon-wave-2'>
                            </div>
                            <div className='border-icon-wave-3'>
                            </div>
                            <img src='/assets/Icon/sec_icon_3.svg' className='w-12 mx-auto'></img>
                        </div>
                    </div>
                    <div className='absolute top-[45%] left-28 hidden lg:block'>
                        <div className='relative flex items-center justify-center'>
                            <div className='border-icon-wave-1'>
                            </div>
                            <div className='border-icon-wave-2'>
                            </div>
                            <div className='border-icon-wave-3'>
                            </div>
                            <img src='/assets/Icon/sec_icon_4.svg' className='w-12 mx-auto'></img>
                        </div>
                    </div>
                    <div className='absolute top-[55%] right-32 hidden lg:block'>
                        <div className='relative flex items-center justify-center'>
                            <div className='border-icon-wave-1'>
                            </div>
                            <div className='border-icon-wave-2'>
                            </div>
                            <div className='border-icon-wave-3'>
                            </div>
                            <img src='/assets/Icon/sec_icon_5.svg' className='w-12 mx-auto'></img>
                        </div>
                    </div>
                </div>
            </div>
            <Marquee className="bg-[#F6F6F6]" loop={0} speed={50} pauseOnHover={true}>
                <div className='flex items-center gap-5 w-full'>
                    <img src='/assets/Marquee/product1.svg' className='w-32'></img>
                    <img src='/assets/Marquee/product2.svg' ></img>
                    <img src='/assets/Marquee/product3.svg' className='w-44'></img>
                    <img src='/assets/Marquee/product4.svg' className='w-36'></img>
                    <img src='/assets/Marquee/product5.svg' className='w-60'></img>
                    <img src='/assets/Marquee/product1.svg' className='w-32'></img>
                    <img src='/assets/Marquee/product2.svg' ></img>
                    <img src='/assets/Marquee/product3.svg' className='w-44'></img>
                    <img src='/assets/Marquee/product4.svg' className='w-36'></img>
                    <img src='/assets/Marquee/product5.svg' className='w-60'></img>
                </div>
            </Marquee>
        </div>
    )
}
