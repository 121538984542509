import React from 'react'

export default function Section5() {
  const Digital = [
    {
      photo: 'sec5_1.svg',
      content_heading: 'NFT & Token Campaign Management ',
      content_para: 'Strategize, launch, and promote NFT and token projects for maximum visibility and success. ',
    },
    {
      photo: 'sec5_2.svg',
      content_heading: 'Community Building & Engagement ',
      content_para: 'Develop active, loyal Web3 communities that drive long-term engagement.  ',
    },
    {
      photo: 'sec5_3.svg',
      content_heading: 'Influencer Marketing for Web3 ',
      content_para: 'Collaborate with Web3 influencers to boost authentic brand visibility. ',
    },
    {
      photo: 'sec5_4.svg',
      content_heading: 'Content Creation & Distribution',
      content_para: 'Create and distribute engaging content tailored to the Web3 audience. ',
    },
    {
      photo: 'sec5_5.svg',
      content_heading: 'Virtual Event Marketing',
      content_para: 'Promote immersive virtual events to engage and showcase your brand.  ',
    },
    {
      photo: 'sec5_6.svg',
      content_heading: 'SEO & Data-Driven Strategies  ',
      content_para: 'Enhance online visibility with advanced SEO and data-driven approaches in Web3.  ',
    }
  ]
  return (
    <div className='min-h-screen py-10 bg-sec1BG bg-cover'>
      <h1 className='text-3xl lg:text-4xl text-center'>Web 3.0 Digital Marketing Services </h1>
      <p className='mt-7 text-center text-black/75 w-[90%] md:w-[60%] mx-auto tracking-widest'>Our advanced Web 3.0 marketing solutions help businesses amplify their digital presence and engage audiences effeffectively </p>
      <div className='w-[90%] mx-auto mt-10 grid md:grid-cols-2 lg:grid-cols-3 gap-10'>
        {Digital.map((item, index) => (
          <div key={index} data-aos='flip-left' data-aos-duration='1500' className='bg-[#0D327E] text-white rounded-lg px-10 py-7 grid content-around'>
            <img src={`/assets/Icon/${item.photo}`}></img>
            <h1 className='text-xl'>{item.content_heading}</h1>
            <p className='mt-2 text-sm text-white/75'>{item.content_para}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
